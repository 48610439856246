import _ from "lodash";
import { useField } from "formik";

import { Checkbox, CheckboxProps } from "@mui/material";

/**
 * @param checkedValue The value to be set when the checkbox is checked.
 */
export const CheckboxField = <T extends any = any>(
  props: Omit<CheckboxProps, "value"> & {
    name: string;
    validate?: (values: any) => string;
    checkedValue: T;
  }
) => {
  const formikArgs = _.pick(props, ["name", "validate"]);
  const [_field, meta, helpers] = useField<T>(formikArgs);

  return (
    <Checkbox
      {..._.omit(props, ["checkedValue"])}
      checked={meta.value === props.checkedValue}
      onChange={(e) => {
        if (e.target.checked) {
          helpers.setValue(props.checkedValue);
        } else {
          helpers.setValue(undefined);
        }
      }}
    />
  );
};
