import _ from "lodash";
import styled, { css } from "styled-components";

import { THTMLDivElementAttributes } from "./typings/html-element.types";

const gridProperties = [
  {
    cssPropertyKey: "grid-template-columns",
    propName: "gridTemplateColumns",
  },
  {
    cssPropertyKey: "gap",
    propName: "gap",
  },
] as const;

const gridPropNames = gridProperties.map((v) => v.propName);

type TGridProps = THTMLDivElementAttributes & {
  [key in (typeof gridProperties)[number]["propName"]]?: React.CSSProperties[key];
};

const UGrid = (props: TGridProps) => {
  const omittedProps = _.omit(
    props,
    gridPropNames
  ) as THTMLDivElementAttributes;

  return <div {...omittedProps} />;
};

export const Grid = styled(UGrid)`
  display: grid;

  ${(props) => {
    return gridProperties
      .filter(({ propName }) => props[propName])
      .map(({ cssPropertyKey, propName }) => {
        return css`
          ${cssPropertyKey}: ${props[propName]};
        `;
      });
  }}
`;
