import React, { forwardRef } from "react";
import styled from "styled-components";

import { EmptyStyledButton } from "./empty-styled-button";
import { THTMLButtonElementAttributes } from "./typings/html-element.types";
import { Tooltip } from "@mui/material";

type TClickableIconProps = Omit<THTMLButtonElementAttributes, "onClick"> & {
  onClick: THTMLButtonElementAttributes["onClick"];
  icon?: React.ReactElement;
  tooltipLabel?: React.ReactNode;
};
export const ClickableIcon = styled(
  forwardRef<HTMLButtonElement, TClickableIconProps>(
    ({ className, onClick, icon, tooltipLabel }, ref) => {
      return (
        <EmptyStyledButton
          type="button"
          ref={ref}
          className={className}
          onClick={onClick}
        >
          {tooltipLabel ? <Tooltip title={tooltipLabel}>{icon}</Tooltip> : icon}
        </EmptyStyledButton>
      );
    }
  )
)`
  display: flex;

  padding: 0.2rem;

  border: none;
  ${({ theme }) => theme.borderRadius.roundStyle};

  background-color: transparent;

  transition: all 0.15s ease-in-out;

  &:hover {
    ${({ theme }) => theme.hover.backgroundStyle};
    ${({ theme }) => theme.hover.clickableStyle};
  }
`;
