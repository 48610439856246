import styled from "styled-components";

import { FlexRow } from "common/flex";
import type { THTMLDivElementAttributes } from "common/typings/html-element.types";

const CardSectionTitle = styled(
  ({
    children,
    action,
    ...props
  }: THTMLDivElementAttributes & {
    action?: React.ReactNode;
  }) => {
    return (
      <div {...props}>
        {children}
        {action ? (
          <>
            <FlexRow flex={1} />
            {action}
          </>
        ) : null}
      </div>
    );
  }
)`
  display: flex;
  align-items: center;

  font-size: 1rem;
  font-weight: 500;
`;

const CardSectionCaption = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;

  margin-top: 1.6rem;
`;

const CardSectionBody = styled.div`
  margin-top: 2.4rem;
`;
const CardSectionFooter = styled.div`
  margin-top: 2.4rem;
`;

const _UCardSection = (props: THTMLDivElementAttributes) => {
  return <div {...props} />;
};
const _CardSection = styled(_UCardSection)`
  --padding-top: 1.2rem;
  --padding-left: 1.6rem;

  ${({ theme }) => theme.breakpoints.up("mobile")} {
    padding: var(--padding-top) var(--padding-left);

    box-shadow: 5px 8px 12px -9px rgba(0, 0, 0, 0.1);
    ${({ theme }) => theme.borderRadius.defaultStyle};
  }

  ${({ theme }) => theme.breakpoints.down("mobile")} {
    padding: var(--padding-top) var(--padding-left);
  }

  margin-bottom: 2.4rem;

  background: white;

  & + & {
    border-bottom: 1px solid lightgray;
  }
`;
export const CardSection = _CardSection as typeof _CardSection & {
  Caption: typeof CardSectionCaption;
  Title: typeof CardSectionTitle;
  Body: typeof CardSectionBody;
  Footer: typeof CardSectionFooter;
};
CardSection.Caption = CardSectionCaption;
CardSection.Title = CardSectionTitle;
CardSection.Body = CardSectionBody;
CardSection.Footer = CardSectionFooter;
