import styled from "styled-components";
import ReactCalendar, { CalendarProps } from "react-calendar";
import dayjs, { Dayjs } from "dayjs";

import "react-calendar/dist/Calendar.css";

// TODO: Resolve Calendar overflow

const UCalendar = ({
  className,
  value,
  onChange,
  ...props
}: Omit<CalendarProps, "className" | "value" | "onChange"> & {
  className?: string;
  value: Dayjs;
  onChange: (value: Dayjs, event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <div className={className}>
      <ReactCalendar
        locale="ko"
        calendarType="gregory"
        formatDay={(locale, date) => dayjs(date).format("D")}
        value={value.toDate()}
        onChange={(date, e) => {
          if (Array.isArray(date)) {
            throw new Error("Unsupport multiple date selection");
          }
          onChange(dayjs(date), e);
        }}
        {...props}
      />
    </div>
  );
};

export const Calendar = styled(UCalendar)`
  display: flex;
  justify-content: center;

  .react-calendar {
    max-width: 350px;
    width: unset;

    border: none;
  }
  .react-calendar__tile--active {
    background: ${({ theme }) => theme.palette.primary.main} !important;
  }
`;
