import _ from "lodash";
import RDT, {
  type TableProps as RDTProps,
  type TableColumn as RDTColumn,
} from "react-data-table-component";

import { PlayIcon } from "@heroicons/react/24/solid";

import { NoData } from "./no-data";

export type DataTableColumn<TData extends any> = RDTColumn<TData>;

export const DataTable = <TData extends any>({
  noDataBody,
  ...props
}: Omit<RDTProps<TData>, "columns"> & {
  noDataBody?: React.ReactNode;
  columns: (RDTProps<TData>["columns"][number] & {
    moreButton?: boolean;
    checkbox?: boolean;
  })[];
}) => {
  const sanitizedColumns = props.columns.map((column) => {
    if (column.moreButton) {
      return {
        ...column,
        minWidth: "44px",
        style: {
          "& > *": {
            marginLeft: "auto",
          },
        },
      };
    }
    if (column.checkbox) {
      return {
        ...column,
        maxWidth: "44px",
        minWidth: "unset",
        style: { paddingLeft: "unset" },
      };
    }

    return column;
  });
  return (
    <RDT<TData>
      {..._.omit(props, "columns")}
      columns={sanitizedColumns}
      // Shows a TableHeader with NoDataComponent
      persistTableHead
      noDataComponent={<NoData body={noDataBody} />}
      sortIcon={
        <div
          style={{
            marginLeft: "0.2rem",
            transform: "rotate(90deg)",
          }}
        >
          <PlayIcon
            style={{
              width: 12,
              marginLeft: 1,
              verticalAlign: "middle",
            }}
          />
        </div>
      }
    />
  );
};
