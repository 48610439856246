import { gql } from "@apollo/client";

import { DangerPanel } from "common/danger-panel";
import { queryFetcher } from "common/graphql/use-query";
import { useModalForm } from "common/modal/hooks/use-modal-form";
import { ModalEventEmitter } from "common/modal/modal";
import { useParams } from "common/react-router-dom/use-params";
import SubmitButton from "common/submit-button";

import { DeleteInterviewParticipantDocument } from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";

gql`
  mutation DeleteInterviewParticipant(
    $groupName: String!
    $interviewId: ID!
    $participantId: ID!
  ) {
    deleteInterviewParticipant(
      groupName: $groupName
      interviewId: $interviewId
      participantId: $participantId
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useDeleteInterviewParticipantModal = ({
  participant,
}: {
  participant: { id: string; name: string };
}) => {
  const toast = useToast();
  const { groupName, interviewId } = useParams<{
    groupName: string;
    interviewId: string;
  }>();

  return useModalForm({
    variant: "centered",
    header: "인터뷰 참가자 삭제",
    body: (
      <DangerPanel>
        정말로 참가자를 삭제하시겠어요?
        <br />
        삭제 이후에 <b>{participant.name}</b>님은 더이상 인터뷰 페이지에 접속할
        수 없어요.
      </DangerPanel>
    ),
    footer: <SubmitButton color="error" children="삭제" />,
    formProps: {
      enableReinitialize: true,
      initialValues: {
        groupName,
        interviewId,
        participantId: participant.id,
      },
      mutation: DeleteInterviewParticipantDocument,
      onComplete: () => {
        toast("인터뷰 대상자 삭제가 완료되었어요.");
        queryFetcher.emit("InterviewDashboardPage");
        ModalEventEmitter.emit("close");
      },
    },
  });
};
