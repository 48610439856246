import { Params, useParams as useParamsOg } from "react-router-dom";

export const useParams = <
  ParamsOrKey extends string | Record<string, string | undefined>
>(): Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
> => {
  const paramsMap = useParamsOg<ParamsOrKey>();

  if ("groupName" in paramsMap) {
    const groupName = paramsMap.groupName as unknown as string;
    return {
      ...paramsMap,
      groupName: groupName.replace("@", ""),
    } as any;
  }

  return paramsMap;
};
