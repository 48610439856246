import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ButtonProps, CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";

import { Button } from "./button";
import { FormError } from "./form-error";

type SubmitButtonProps = Pick<ButtonProps, "variant" | "color"> & {
  className?: string;
  children?: React.ReactNode;
  variant?: "contained" | "text" | "outlined";
  onClick?: () => void | null;
  withFormError?: boolean;
};
function UnstyledSubmitButton({
  children = "제출",
  className,
  onClick = null,
  variant = "contained",
  color = "primary",
  withFormError = true,
}: SubmitButtonProps) {
  const formikProps = useFormikContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleClick() {
    formikProps.submitForm();
  }

  useEffect(() => {
    setIsSubmitting(formikProps.isSubmitting);
    if (formikProps.isSubmitting) {
      const timer = setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formikProps.isSubmitting]);

  return (
    <div className={className}>
      {withFormError && <FormError />}
      <Button
        variant={variant}
        color={color}
        onClick={onClick ? onClick : handleClick}
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <CircularProgress size={15} sx={{ marginRight: "6px" }} />
        )}
        {children}
      </Button>
    </div>
  );
}
const SubmitButton = styled(UnstyledSubmitButton)`
  ${Button} {
    width: 100%;
    height: 3.6rem;

    border-radius: 0.4rem;

    color: white;
    font-weight: 600;
  }
`;
export default SubmitButton;
