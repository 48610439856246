import { useEffect, useState } from "react";
import { useField } from "formik";
import dayjs, { Dayjs } from "dayjs";

import {
  DateTimePickerProps,
  DateTimePicker as MuiDateTimePicker,
} from "@mui/x-date-pickers";

import { TextFieldBase } from "./text-field";
import { _customTheme } from "theme/mui-theme";

export default function DateTimePicker({
  label = "",
  helperText,
  validate,
  ...props
}: DateTimePickerProps<any> & {
  name: string;
  helperText?: string;
  validate?: (value: Dayjs) => string;
}) {
  const [error, setError] = useState("");
  const [field, meta, helpers] = useField<Dayjs>({
    name: props.name,
    validate: (value) => {
      const additionalValidation = validate?.(value);
      if (additionalValidation) {
        return additionalValidation;
      }

      if (!dayjs(value).isValid()) {
        return "올바른 시간을 입력해주세요.";
      }
    },
  });

  useEffect(() => {
    meta.touched && setError(meta.error || "");
  }, [meta]);

  return (
    <MuiDateTimePicker
      slots={{
        // FIXME: Remove any
        textField: TextFieldBase as any,
      }}
      slotProps={{
        popper: {
          style: {
            zIndex: _customTheme.zIndex.muiPicker,
          },
        },
        dialog: {
          disablePortal: true,
          style: {
            zIndex: _customTheme.zIndex.muiPicker,
          },
        },
        textField: {
          error: Boolean(error),
          helperText: Boolean(error) ? error : helperText,
          label,
          onBlur: (e) => {
            helpers.setTouched(true);
            field.onBlur(e);
          },
        },
      }}
      name={field.name}
      value={field.value}
      onChange={(v) => {
        helpers.setValue(v);
      }}
      // FIXME: YYYY-MM-DD A H:mm NOT WORKING w/Dayjs
      format="YYYY-MM-DD H:mm"
    />
  );
}
