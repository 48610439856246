import styled from "styled-components";

import { Footer } from "common/footer";

import { NonUserParticipantNavigationBar } from "./non-user-participant-navigation-bar";

export const NonUserParticipantPage = styled(
  ({
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode;
  }) => {
    return (
      <>
        <div className={className}>
          <NonUserParticipantNavigationBar />
          {children}
        </div>
        <Footer />
      </>
    );
  }
)(
  ({ theme }) => `
  height: auto;
  min-height: ${theme.vh(100)};

  padding-top: ${theme.navigationBar.slim.height};

  background-color: ${theme.page.backgroundColor};
`
);
