import queryString from "query-string";
import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const useQueryString = <T extends Record<string, any>>() => {
  const location = useLocation();
  const { search } = location;

  const [_p, setter] = useSearchParams();

  const params = useMemo(() => {
    return queryString.parse(search);
  }, [search]);

  return { search: params as T, setter };
};
