import _ from "lodash";
import styled from "styled-components";
import { useField } from "formik";

import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  FormLabel,
  FormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioGroupProps,
  FormLabelProps,
  FormControl,
} from "@mui/material";

type TRadioFieldItem = {
  label: string | React.ReactElement;
  value: string;
};

export const RadioField = styled(
  ({
    label,
    items,
    ...props
  }: RadioGroupProps & {
    label?: React.ReactNode;
    items: TRadioFieldItem[];
    name: string;
    validate?: (values: string) => string;
  }) => {
    const formikArgs = _.pick(props, ["name", "validate"]);
    const [field] = useField<string>(formikArgs);

    return (
      <FormControl>
        <RadioGroupLabel>{label}</RadioGroupLabel>
        <RadioGroup {...field} {...props}>
          {items.map(({ label, value }) => (
            <Radio key={`${label}__${value}`} label={label} value={value} />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
)``;

export const Radio = styled(
  (props: Omit<MuiFormControlLabelProps, "control">) => {
    return <FormControlLabel {...props} control={<MuiRadio />} />;
  }
)``;

export const RadioGroupLabel = styled((props: FormLabelProps) => {
  return <FormLabel {...props} />;
})``;

export const RadioGroup = styled((props: RadioGroupProps) => {
  return <MuiRadioGroup {...props} />;
})``;

RadioGroup.defaultProps = {
  row: true,
};
