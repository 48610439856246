import styled, { css } from "styled-components";
import dayjs, { Dayjs } from "dayjs";

import { gql } from "@apollo/client";

import { PageBody } from "common/page-body";
import { Calendar } from "common/calendar";
import { QueryRenderer } from "common/graphql/query-renderer";
import Form from "common/form";
import { EmptyStatus } from "common/empty-status";
import { Card, CardContent } from "common/card";
import SubmitButton from "common/submit-button";

import { useAuthContext } from "auth/context";
import {
  NonUserParticipantInterviewReservationMutationDocument,
  NonUserParticipantInterviewReservationPageQueryDocument,
} from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";
import { NonUserParticipantPage } from "./non-user-participant-page";

gql`
  query NonUserParticipantInterviewReservationPageQuery {
    nonUserParticipantQuery {
      interviewTime {
        timestamp
      }
      interviewTimes {
        timestamp
        reservable
      }
    }
  }
`;
gql`
  mutation NonUserParticipantInterviewReservationMutation(
    $selectedDate: Date!
  ) {
    nonUserParticipantInterviewReservation(selectedDate: $selectedDate) {
      ok
      errors {
        _
      }
    }
  }
`;

const StyledCard = styled(Card)<{
  selected: boolean;
  isUserInterview: boolean;
}>`
  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(0, 0, 0, 0.06);
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: #139487;
      color: white;
    `}
    ${({ isUserInterview }) =>
    isUserInterview &&
    css`
      background: #7f9dc0;
      color: white;
    `}
`;
const DisabledText = styled.span``;
export const NonUserParticipantInterviewReservationPage = styled(
  ({ className }: { className?: string }) => {
    const {
      nonUserParticipant: { name },
    } = useAuthContext();
    const toast = useToast();
    return (
      <QueryRenderer
        variables={{}}
        query={NonUserParticipantInterviewReservationPageQueryDocument}
      >
        {({
          data: {
            nonUserParticipantQuery: {
              interviewTime: userInterviewTime,
              interviewTimes,
            },
          },
          refetch,
        }) => (
          <NonUserParticipantPage className={className}>
            <PageBody>
              <PageBody.Title>
                {userInterviewTime
                  ? "인터뷰 시간을 변경해볼까요? 👀"
                  : "⏰ 인터뷰 시간을 예약해주세요!"}
              </PageBody.Title>
              <Form
                initialValues={{
                  selectedDate:
                    userInterviewTime?.timestamp ??
                    _.find(interviewTimes, ({ reservable }) => reservable)
                      ?.timestamp ??
                    dayjs(),
                }}
                mutation={
                  NonUserParticipantInterviewReservationMutationDocument
                }
                onComplete={() => {
                  refetch();
                  toast("인터뷰 예약이 완료되었어요.");
                }}
              >
                {({ values, setFieldValue, formError }) => {
                  const interviewTimesForTheSelectedDate =
                    interviewTimes.filter(({ timestamp }) =>
                      values.selectedDate.isSame(timestamp, "day")
                    );
                  return (
                    <PageBody.Body>
                      <Calendar
                        // TODO: separate calendar date with selected date
                        value={values.selectedDate as Dayjs}
                        onChange={(v, e) => setFieldValue("selectedDate", v)}
                        tileDisabled={({ activeStartDate, date, view }) =>
                          !_.includes(
                            _.map(interviewTimes, ({ timestamp }) =>
                              dayjs(timestamp).format("YYYY-MM-DD")
                            ),
                            dayjs(date).format("YYYY-MM-DD")
                          )
                        }
                      />
                      {interviewTimesForTheSelectedDate.length === 0 ? (
                        <EmptyStatus>
                          관리자가 등록한 면접 시간이 없어요.
                        </EmptyStatus>
                      ) : (
                        <>
                          {interviewTimesForTheSelectedDate.map(
                            (interviewTime, index) => {
                              const isUserInterview =
                                userInterviewTime?.timestamp.isSame(
                                  interviewTime.timestamp
                                ) ?? false;
                              return (
                                <StyledCard
                                  key={interviewTime.timestamp.toString()}
                                  disabled={!interviewTime.reservable}
                                  selected={values.selectedDate.isSame(
                                    interviewTime.timestamp
                                  )}
                                  isUserInterview={isUserInterview}
                                  onClick={(e) => {
                                    setFieldValue(
                                      "selectedDate",
                                      interviewTime.timestamp
                                    );
                                  }}
                                >
                                  <CardContent alignSelf="flex-end">
                                    <span>
                                      {dayjs(interviewTime.timestamp).format(
                                        "a h시 mm분"
                                      )}
                                    </span>
                                    <span>
                                      {isUserInterview
                                        ? `${name}님의 인터뷰 시간 👍`
                                        : !interviewTime.reservable && (
                                            <DisabledText>
                                              선택 불가
                                            </DisabledText>
                                          )}
                                    </span>
                                  </CardContent>
                                </StyledCard>
                              );
                            }
                          )}
                          <SubmitButton>신청하기</SubmitButton>
                        </>
                      )}
                    </PageBody.Body>
                  );
                }}
              </Form>
            </PageBody>
          </NonUserParticipantPage>
        )}
      </QueryRenderer>
    );
  }
)`
  ${StyledCard} {
    margin-bottom: 1.2rem;
    min-width: 275px;

    ${CardContent} {
      display: flex;
      justify-content: space-between;
    }
  }
  ${DisabledText} {
    color: gray;
    margin-left: auto;
  }
  ${SubmitButton} {
    margin-top: 2.4rem;
  }

  ${Calendar} {
    padding-bottom: 4rem;
  }
`;
