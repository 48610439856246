import { createTheme, darken, lighten } from "@mui/material/styles";
declare module "@mui/material/styles" {
  interface PaletteColor {
    submit?: string;
  }
  interface SimplePaletteColorOptions {
    submit?: string;
  }
}

declare module "styled-components" {
  export interface DefaultTheme extends TBindingTheme {}
}

type TBindingTheme = typeof _muiTheme & typeof _customTheme;

const _muiTheme = {
  typography: {
    fontFamily: ["Pretendard", "-apple-system"].join(", "),
  },
  palette: {
    primary: {
      main: "#1bb69d",
      contrastText: "#fff",
    },
    success: {
      main: "rgb(19, 148, 135)",
    },
    error: {
      main: "#f44336",
      dark: "#d32f2f",
    },
  },
};

export const _customTheme = {
  lighten,
  darken,
  gray: {
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  color: {
    black: "var(--black)",
  },
  vh: (size: number) => {
    return `calc(var(--vh, 1vh) * ${size})`;
  },
  navigationBar: {
    primary: {
      width: "14.2rem",
    },
    slim: {
      height: "48px",
    },
  },
  page: {
    title: {
      margin: {
        primary: { bottom: "2.4rem" },
        mobile: { bottom: "1.8rem", left: "0" },
      },
    },
    backgroundColor: "#f4f8fa",
    maxWidth: "1240px",
    padding: {
      primary: {
        top: "1.6rem",
        left: "2.4rem",
      },
      mobile: {
        top: "1.2rem",
        left: "1.6rem",
      },
    },
  },
  zIndex: {
    toast: 100,
    navigationBar: 50,
    modal: {
      background: 60,
      dialog: 70,
    },
    muiPicker: 90,
  },
  modal: {
    padding: {
      top: "1.6rem",
      bottom: "1.2rem",
      right: "2rem",
    },
  },
  flex: {
    row: `display: flex;`,
    col: `display: flex; flex-direction: column;`,
    ible: `flex: 1;`,
    cc: `display:flex; justify-content: center; align-items: center;`,
  },
  hover: {
    backgroundStyle: "background-color: rgba(0, 0, 0, 0.05);",
    clickableStyle: "cursor: pointer;",
  },
  borderRadius: {
    defaultStyle: "border-radius: 12px;",
    roundStyle: "border-radius: 50%;",
  },
  boxShadow: {
    defaultStyle: "box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 3%);",
  },
  breakpoints: {
    up: (deviceType: "mobile") => {
      switch (deviceType) {
        case "mobile":
          return `@media only screen and (min-width: ${deviceSizes.mobile}px)`;
      }
    },
    down: (deviceType: "mobile") => {
      switch (deviceType) {
        case "mobile":
          return `@media only screen and (max-width: ${deviceSizes.mobile}px)`;
      }
    },
  },
};

const deviceSizes = {
  mobile: 1224,
} as const;

export const theme = createTheme(_muiTheme, _customTheme);
