import { gql } from "@apollo/client";

import { EmailField } from "common/email-field";
import { useModalForm } from "common/modal/hooks/use-modal-form";
import { useParams } from "common/react-router-dom/use-params";
import SubmitButton from "common/submit-button";
import { Table, TableBody, TableCell, TableRow } from "common/table";
import useToast from "common/toast/use-toast";

import { InviteGroupAdminMutationDocument } from "gql-codegen/graphql";
import { queryFetcher } from "common/graphql/use-query";
import { ModalEventEmitter } from "common/modal/modal";
import TextField from "common/text-field";

gql`
  mutation InviteGroupAdminMutation(
    $groupName: String!
    $inviteeName: String!
    $inviteeEmailAddress: String!
  ) {
    inviteGroupAdmin(
      groupName: $groupName
      inviteeName: $inviteeName
      inviteeEmailAddress: $inviteeEmailAddress
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useInviteGroupAdminModalForm = () => {
  const { groupName } = useParams<{ groupName: string }>();
  const toast = useToast();

  return useModalForm({
    header: "관리자 초대",
    body: (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">이름</TableCell>
            <TableCell>
              <TextField
                name="inviteeName"
                placeholder="박철수"
                validate={(value) => {
                  if (!value) {
                    return "이름을 입력해주세요.";
                  }
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">이메일</TableCell>
            <TableCell>
              <EmailField
                name="inviteeEmailAddress"
                placeholder="admin@binding.at"
                validate={(value) => {
                  if (!value) {
                    return "이메일 주소를 입력해주세요.";
                  }
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    ),
    footer: <SubmitButton children="초대하기" />,
    formProps: {
      initialValues: {
        groupName,
        inviteeName: "",
        inviteeEmailAddress: "",
      },
      mutation: InviteGroupAdminMutationDocument,
      onComplete: () => {
        toast("관리자 초대 메일을 성공적으로 발송했어요.");
        queryFetcher.emit("groupAdminListSection");
        ModalEventEmitter.emit("close");
      },
    },
  });
};
