import styled from "styled-components";
import { FieldHookConfig } from "formik";

import TextField, { TextFieldProps } from "./text-field";

export const validateEmail = (email: string) => {
  const regEx = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
  return regEx.test(email);
};

export const EmailField = styled(
  (
    props: Omit<FieldHookConfig<string>, "validate" | "as"> & TextFieldProps
  ) => {
    return (
      <TextField
        name="email"
        validate={(email) => {
          if (!validateEmail(email)) {
            return "올바른 이메일을 입력해주세요.";
          }
        }}
        inputProps={{ maxLength: 254 }}
        {...props}
      />
    );
  }
)``;
