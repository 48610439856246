import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Tab as MuiTab, Tabs as MuiTabs, TabsProps } from "@mui/material";

import type { THTMLDivElementAttributes } from "common/typings/html-element.types";
import { useQueryString } from "common/query-string/hooks/use-query-string";

const TabPanel = styled(
  ({
    children,
    index,
    currentTabIndex,
    ...divAttrs
  }: THTMLDivElementAttributes & {
    index: number;
    currentTabIndex: number;
  }) => {
    return (
      <div
        role="tabpanel"
        hidden={index !== currentTabIndex}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...divAttrs}
      >
        {index === currentTabIndex && children}
      </div>
    );
  }
)`
  width: 100%;
  padding: 1.8rem 0;
`;

type TTabItem = {
  id?: string;
  label: React.ReactNode;
  panel: React.ReactNode;
};
const UTab = ({
  className,
  items,
  ...tabProps
}: Omit<TabsProps, "value" | "onChange"> & { items: TTabItem[] }) => {
  const { search, setter } = useQueryString();

  const decideCurrentTabIndex = () => {
    const index = items.findIndex(({ id }) => id === search.id);
    return index === -1 ? 0 : index;
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(
    decideCurrentTabIndex()
  );

  useEffect(() => {
    setter({ id: items[currentTabIndex].id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabIndex]);

  const a11yProps = (index: number) => {
    return {
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className={className}>
      <MuiTabs
        variant="scrollable"
        value={currentTabIndex}
        onChange={(_, newValue: number) => setCurrentTabIndex(newValue)}
        {...tabProps}
      >
        {items.map(({ id, label }, index) => (
          <MuiTab
            key={id}
            id={id}
            label={label}
            disableRipple
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>
      {items.map(({ id, panel }, index) => (
        <TabPanel
          key={id}
          index={index}
          currentTabIndex={currentTabIndex}
          children={panel}
        />
      ))}
    </div>
  );
};
export const Tab = styled(UTab)`
  display: flex;

  .MuiTabs-indicator {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 9999px;
    opacity: 0.15;
  }

  .MuiTabs-root {
    min-height: unset;
  }

  .MuiTab-root {
    min-height: 2.4rem;
  }
`;
