import dayjs, { Dayjs } from "dayjs";
import { createContext, useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { EnhancedStore, configureStore } from "@reduxjs/toolkit";

import { useParams } from "common/react-router-dom/use-params";

import {
  CreateOrDeleteInterviewTimeCandidateDocument,
  InterviewTimeType,
} from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";
import { queryFetcher } from "common/graphql/use-query";

gql`
  mutation CreateOrDeleteInterviewTimeCandidate(
    $groupName: String!
    $interviewId: ID!
    $action: String!
    $timestamp: Date!
  ) {
    createOrDeleteInterviewTimeCandidate(
      groupName: $groupName
      interviewId: $interviewId
      action: $action
      timestamp: $timestamp
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useInterviewTimeCandidatesContext = () =>
  useContext(InterviewTimeCandidatesContext);

type TInterviewTimeCandidate = InterviewTimeType;

type TInterviewTimeCandidatesContext = {
  currentDate: Dayjs;
  setCurrentDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  interviewTimeCandidates: TInterviewTimeCandidate[];
  setInterviewTimeCandidates: React.Dispatch<
    React.SetStateAction<TInterviewTimeCandidate[]>
  >;
};

export const InterviewTimeCandidatesContext =
  createContext<TInterviewTimeCandidatesContext>(null);

export const InterviewTimeCandidatesStore = configureStore({
  reducer: (_type, { type, payload }) => {
    switch (type) {
      case "CREATE_INTERVIEW_TIME_CANDIDATE":
        return {
          ...payload,
          action: "create",
        };
      case "DELETE_INTERVIEW_TIME_CANDIDATE":
        return {
          ...payload,
          action: "delete",
        };
      default:
        return null;
    }
  },
});

export const useStoreSubscriber = <T extends any>(
  effect: (state: T) => void,
  Store: EnhancedStore<T>
) => {
  useEffect(() => {
    const unsubscribe = Store.subscribe(() => {
      effect(Store.getState());
    });
    return () => unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const InterviewTimeCandidatesContextProvider = ({
  interviewTimeCandidates: initialInterviewTimeCandidates,
  children,
}: {
  interviewTimeCandidates: TInterviewTimeCandidate[];
  children: React.ReactNode;
}) => {
  const { groupName, interviewId } = useParams<{
    groupName: string;
    interviewId: string;
  }>();

  const initialSelectedDate =
    initialInterviewTimeCandidates[0]?.timestamp ?? dayjs();
  const [currentDate, setCurrentDate] = useState<Dayjs>(
    initialSelectedDate.startOf("d")
  );
  const [interviewTimeCandidates, setInterviewTimeCandidates] = useState<
    TInterviewTimeCandidate[]
  >(initialInterviewTimeCandidates);

  const [mutate] = useMutation(CreateOrDeleteInterviewTimeCandidateDocument);

  const toast = useToast();

  useStoreSubscriber<{
    action: "create" | "delete";
    timestamp: Dayjs;
  }>(async (state) => {
    try {
      const result = await mutate({
        variables: {
          groupName: groupName,
          interviewId: interviewId,
          ...state,
        },
      });

      queryFetcher.emit("InterviewDashboardPage");

      const data = result.data.createOrDeleteInterviewTimeCandidate;

      if (data.ok) {
        if (state.action === "create") {
          toast("인터뷰 시간이 추가되었어요.");

          setInterviewTimeCandidates((prev) => [
            ...prev,
            {
              timestamp: state.timestamp,
              reservable: true,
              deletable: true,
            },
          ]);
        }
        if (state.action === "delete") {
          toast("인터뷰 시간이 삭제되었어요.");

          setInterviewTimeCandidates((prev) =>
            _.filter(
              prev,
              ({ timestamp }) => !timestamp.isSame(state.timestamp)
            )
          );
        }
      } else if (data.errors) {
        throw new Error(data.errors._);
      }
    } catch (e) {
      toast(e.message ?? "인터뷰 시간을 추가/삭제할 수 없어요.", "error");
    }
  }, InterviewTimeCandidatesStore);

  return (
    <InterviewTimeCandidatesContext.Provider
      value={{
        currentDate,
        setCurrentDate,
        interviewTimeCandidates,
        setInterviewTimeCandidates,
      }}
      children={children}
    />
  );
};
