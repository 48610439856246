import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { gql, useLazyQuery } from "@apollo/client";

import { matchPath } from "common/react-router-dom/match-path";

import { GroupQueryDocument, GroupQueryQuery } from "gql-codegen/graphql";

gql`
  query GroupQuery($groupName: String!) {
    group(groupName: $groupName) {
      id
      name
      fullName
      pfKakaoURL
    }
  }
`;

const GroupContext = createContext<{
  group: GroupQueryQuery["group"] | null;
  refetchContext: () => void;
}>({} as any);

export const useGroupContext = () => useContext(GroupContext);

export const GroupContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const matched = matchPath<"groupName", string>(
    {
      path: "/group/:groupName",
      end: false,
    },
    window.location.pathname
  );

  const [group, setGroup] = useState(null);
  const [queryGroup, { loading, error, data, refetch }] =
    useLazyQuery(GroupQueryDocument);

  useEffect(() => {
    if (matched === null) {
      setGroup(null);
    } else {
      const groupName = matched.params.groupName;
      queryGroup({
        variables: {
          groupName:
            /* 한글일 수 있기 때문에 decode해서 넣어준다 */ decodeURIComponent(
              groupName
            ),
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (data) {
      setGroup(data.group);
    }
  }, [data]);

  if (group) {
    return (
      <GroupContext.Provider
        value={{ group, refetchContext: refetch }}
        children={children}
      />
    );
  }

  return children;
};
