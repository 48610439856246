import _ from "lodash";
import { useState } from "react";

import { gql } from "@apollo/client";

import { DataTable } from "common/data-table";
import { QueryRenderer } from "common/graphql/query-renderer";
import SubmitButton from "common/submit-button";
import Form from "common/form";
import { queryFetcher } from "common/graphql/use-query";
import { Modal, ModalEventEmitter } from "common/modal/modal";
import { CheckboxField } from "common/checkbox-field";
import { useParams } from "common/react-router-dom/use-params";

import {
  EditInterviewHostsMutationDocument,
  UseEditInterviewHostsModalQueryDocument,
} from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";

gql`
  query UseEditInterviewHostsModalQuery(
    $groupName: String!
    $interviewId: ID!
  ) {
    interviewHosts(groupName: $groupName, interviewId: $interviewId) {
      id
      username
      phoneNumber
      emailAddress
    }
    groupAdmins(groupName: $groupName) {
      id
      username
      phoneNumber
      emailAddress
    }
  }
`;

gql`
  mutation EditInterviewHostsMutation(
    $groupName: String!
    $interviewId: ID!
    $userIds: [ID!]!
  ) {
    editInterviewHosts(
      groupName: $groupName
      interviewId: $interviewId
      userIds: $userIds
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useEditInterviewHostsModal = () => {
  const { groupName, interviewId } = useParams<{
    groupName: string;
    interviewId: string;
  }>();
  const [show, setShow] = useState(false);

  const toast = useToast();

  return {
    show: () => setShow(true),
    hide: () => setShow(false),
    renderModal: () => {
      return (
        <QueryRenderer
          query={UseEditInterviewHostsModalQueryDocument}
          variables={{ groupName, interviewId }}
        >
          {({ data: { groupAdmins, interviewHosts } }) => (
            <Form
              mutation={EditInterviewHostsMutationDocument}
              initialValues={{
                groupName,
                interviewId,
                userIds: interviewHosts.map((host) => host.id),
              }}
              sanitize={(values) => {
                values.userIds = _.compact(values.userIds);
              }}
              onComplete={() => {
                queryFetcher.emit("InterviewDashboardPage");
                ModalEventEmitter.emit("close");
                toast("면접관 추가/변경이 완료되었어요.");
              }}
            >
              {({ values }) => (
                <Modal
                  variant="centered"
                  show={show}
                  onClose={() => setShow(false)}
                  header="면접관 추가/변경"
                  body={
                    <DataTable
                      columns={[
                        {
                          id: "checkbox",
                          checkbox: true,
                          cell: (row, index) => (
                            <CheckboxField<string>
                              name={`userIds[${index}]`}
                              defaultChecked={values.userIds.includes(row.id)}
                              checkedValue={row.id}
                            />
                          ),
                        },
                        {
                          id: "username",
                          name: "이름",
                          selector: (row) => row.username,
                        },
                        {
                          id: "phoneNumber",
                          name: "휴대폰 번호",
                          selector: (row) => row.phoneNumber,
                        },
                        {
                          id: "emailAddress",
                          name: "이메일 주소",
                          selector: (row) => row.emailAddress,
                        },
                      ]}
                      data={groupAdmins}
                    />
                  }
                  footer={<SubmitButton children="변경" />}
                />
              )}
            </Form>
          )}
        </QueryRenderer>
      );
    },
  };
};
