import { TypedDocumentNode } from "@apollo/client";

import { useQuery } from "./use-query";

export type TQueryRendererProps<TVariables, TResult> = {
  query: TypedDocumentNode<TResult, TVariables>;
  variables: TVariables;
  children: (props: {
    data: TResult;
    refetch: (variables?: Partial<TVariables>) => Promise<any>;
  }) => React.ReactElement;
  refetchKey?: string;
};
export const QueryRenderer = <TVariables, TResult>({
  query,
  variables,
  children,
  refetchKey,
}: TQueryRendererProps<TVariables, TResult>) => {
  const { data, loading, refetch } = useQuery(query, {
    variables,
    refetchKey,
  });

  if (loading || !data) {
    return null;
  }
  return children({ data, refetch });
};
