import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Alert, Link } from "@mui/material";
import {
  PaperAirplaneIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";

import { CheckboxField } from "common/checkbox-field";
import { DataTable } from "common/data-table";
import Form from "common/form";
import { QueryRenderer } from "common/graphql/query-renderer";
import Page from "common/page";
import { PageBody } from "common/page-body";
import { CardSection, SubSection } from "common/section";
import SubmitButton from "common/submit-button";
import { useParams } from "common/react-router-dom/use-params";
import useToast from "common/toast/use-toast";

import {
  InterviewParticipantInvitationPageQueryDocument,
  InviteInterviewParticipantsDocument,
} from "gql-codegen/graphql";

gql`
  query InterviewParticipantInvitationPageQuery(
    $groupName: String!
    $interviewId: ID!
  ) {
    interviewInvitationLink(groupName: $groupName, interviewId: $interviewId) {
      url
      token
    }
    interviewParticipants(groupName: $groupName, interviewId: $interviewId) {
      id
      name
      phoneNumber
      emailAddress
    }
  }
`;
gql`
  mutation InviteInterviewParticipants(
    $groupName: String!
    $interviewId: ID!
    $invitationToken: String!
    $participantIds: [ID!]!
  ) {
    inviteInterviewParticipants(
      groupName: $groupName
      interviewId: $interviewId
      invitationToken: $invitationToken
      participantIds: $participantIds
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

const SLink = styled(Link)`
  display: inline-flex;
  gap: 0.2rem;

  svg {
    flex-shrink: 0;
  }
`;
export const InterviewParticipantInvitationPage = styled(
  ({ className }: { className?: string }) => {
    const toast = useToast();
    const { groupName, interviewId } = useParams<{
      groupName: string;
      interviewId: string;
    }>();
    return (
      <Page className={className}>
        <QueryRenderer
          query={InterviewParticipantInvitationPageQueryDocument}
          variables={{ groupName, interviewId }}
        >
          {({ data: { interviewInvitationLink, interviewParticipants } }) => (
            <PageBody>
              <PageBody.Title>인터뷰 예약 안내 알림 전송하기</PageBody.Title>
              <PageBody.Body>
                <Alert
                  sx={{ wordBreak: "break-all", marginBottom: "1.2rem" }}
                  variant="outlined"
                  icon={
                    <PaperAirplaneIcon
                      fontSize="inherit"
                      width={24}
                      style={{ transform: "rotate(-25deg)" }}
                    />
                  }
                  severity="success"
                >
                  아래 링크를 직접 전달해주셔도 되어요. <br />
                  <span>
                    <CopyToClipboard
                      text={interviewInvitationLink.url}
                      onCopy={() => {
                        toast("복사가 완료되었어요.");
                      }}
                    >
                      <SLink variant="body2" href="#">
                        <DocumentDuplicateIcon width={18} />
                        {interviewInvitationLink.url}
                      </SLink>
                    </CopyToClipboard>
                  </span>
                </Alert>
                <CardSection>
                  <CardSection.Title>
                    인터뷰 예약 안내 알림 전송
                  </CardSection.Title>
                  <Form
                    mutation={InviteInterviewParticipantsDocument}
                    initialValues={{
                      groupName,
                      interviewId,
                      participantIds: [],
                      invitationToken: interviewInvitationLink.token,
                    }}
                    validate={(values) => {
                      if (values.participantIds.length === 0) {
                        return { _: "대상자를 선택해주세요." };
                      }
                    }}
                    onComplete={() => {
                      toast("인터뷰 초대를 완료했어요.");
                    }}
                  >
                    {({ values }) => (
                      <>
                        <CardSection.Body>
                          <SubSection>
                            <SubSection.Title>
                              <div>
                                인터뷰 예약 안내 카카오톡 메세지와 메일을 발송할
                                대상자를 선택해주세요.
                              </div>
                              <CardSection.Caption
                                style={{ marginTop: "unset" }}
                              >
                                *카카오톡에 가입되지 않은 번호로는 문자메세지가
                                발송되어요.
                              </CardSection.Caption>
                            </SubSection.Title>
                            <SubSection.Body>
                              <DataTable
                                columns={[
                                  {
                                    id: "checkbox",
                                    checkbox: true,
                                    cell: (row, index) => (
                                      <CheckboxField<string>
                                        name={`participantIds[${index}]`}
                                        defaultChecked={values.participantIds?.includes(
                                          row.id
                                        )}
                                        checkedValue={row.id}
                                      />
                                    ),
                                  },
                                  {
                                    id: "name",
                                    name: "이름",
                                    selector: (row) => row.name,
                                  },
                                  {
                                    id: "phoneNumber",
                                    name: "휴대폰 번호",
                                    selector: (row) => row.phoneNumber,
                                  },
                                  {
                                    id: "emailAddress",
                                    name: "이메일 주소",
                                    selector: (row) => row.emailAddress,
                                  },
                                ]}
                                data={interviewParticipants}
                              />
                            </SubSection.Body>
                          </SubSection>
                        </CardSection.Body>
                        <CardSection.Footer>
                          <SubmitButton children="인터뷰 초대하기" />
                        </CardSection.Footer>
                      </>
                    )}
                  </Form>
                </CardSection>
              </PageBody.Body>
            </PageBody>
          )}
        </QueryRenderer>
      </Page>
    );
  }
)``;
