import styled from "styled-components";
import { THTMLButtonElementAttributes } from "./typings/html-element.types";

export const EmptyStyledButton = styled(
  (props: THTMLButtonElementAttributes) => {
    return <button {...props} />;
  }
)`
  background-color: unset;
  border: unset;
`;
