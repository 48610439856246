import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";

import { gql } from "@apollo/client";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import { FlexCol } from "common/flex";
import Page from "common/page";
import { PageBody } from "common/page-body";
import { CardSection } from "common/section";
import { useParams } from "common/react-router-dom/use-params";
import { Tab } from "common/tab/tab";
import { ClickableIcon } from "common/clickable-icon";
import { DataTable } from "common/data-table";
import { QueryRenderer } from "common/graphql/query-renderer";
import SubmitButton from "common/submit-button";
import TextField from "common/text-field";
import { RadioField } from "common/radio-field";
import Form from "common/form";
import { Badge } from "common/badge";
import { Panel } from "common/panel";
import useToast from "common/toast/use-toast";
import { KebabMenu } from "common/kebab-menu/kebab-menu";

import { useEditInterviewHostsModal } from "./hooks/use-edit-interview-hosts-modal";
import {
  EInterviewType,
  EditInterviewTypeeDocument,
  InterviewDashboardPageHomeTabQueryDocument,
  InterviewDashboardPageQueryDocument,
  InterviewDashboardPageSettingTabQueryDocument,
} from "gql-codegen/graphql";
import { InterviewTimeCandidatesSettingSection } from "./interview-time-setting-section/interview-time-candidates-setting-section/interview-time-candidates-setting-section";
import { InterviewReservableTimeRangeSettingSection } from "./interview-reservable-time-range-setting-section";
import { InterviewScheduleInlineSection } from "./interview-schedule-inline-section";
import { useDeleteInterviewParticipantModal } from "./hooks/use-delete-interview-participant-modal";
import { InterviewDashboardSection } from "./interview-dashboard-section/interview-dashboard-section";

gql`
  query InterviewDashboardPageQuery($groupName: String!, $interviewId: ID!) {
    interviewHosts(groupName: $groupName, interviewId: $interviewId) {
      id
      username
      phoneNumber
      emailAddress
    }
    interviewParticipants(groupName: $groupName, interviewId: $interviewId) {
      id
      name
      phoneNumber
      emailAddress
      hasReservedInterview
    }
  }
`;
gql`
  query InterviewDashboardPageSettingTabQuery(
    $groupName: String!
    $interviewId: ID!
  ) {
    interview(groupName: $groupName, id: $interviewId) {
      title
      reservableTimeFrom
      reservableTimeTo
      type
      meetingURL
      location
    }
    interviewTimes(groupName: $groupName, interviewId: $interviewId) {
      timestamp
      reservable
      deletable
    }
  }
`;
gql`
  mutation EditInterviewTypee(
    $groupName: String!
    $interviewId: ID!
    $type: EInterviewType!
    $meetingURL: String
    $location: String
  ) {
    editInterviewType(
      groupName: $groupName
      interviewId: $interviewId
      type: $type
      meetingURL: $meetingURL
      location: $location
    ) {
      ok
    }
  }
`;

gql`
  query InterviewDashboardPageHomeTabQuery(
    $groupName: String!
    $interviewId: ID!
  ) {
    interviewTimes(groupName: $groupName, interviewId: $interviewId) {
      timestamp
      participant {
        id
        name
      }
    }
    interviewDashboard(groupName: $groupName, interviewId: $interviewId) {
      interview {
        upcoming
        completed
      }
      interviewParticipant {
        total
        reserved
        unReserved
      }
      interviewHost {
        total
      }
    }
  }
`;

const MB = ({
  participant,
}: {
  participant: {
    id: string;
    name: string;
    hasReservedInterview: boolean;
  };
}) => {
  const { renderModal, show } = useDeleteInterviewParticipantModal({
    participant,
  });
  return (
    <>
      {renderModal()}
      <KebabMenu
        // TODO: disable button and show tooltip describing why it's disabled
        items={[
          !participant.hasReservedInterview && {
            label: "삭제",
            onClick: () => show(),
          },
          // TODO: 예약한 시간 회수하기
        ]}
      />
    </>
  );
};

export const InterviewDashboardPage = styled(
  ({ className }: { className?: string }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { groupName, interviewId } = useParams<{
      groupName: string;
      interviewId: string;
    }>();

    const { renderModal, show: showEditInterviewHostsModal } =
      useEditInterviewHostsModal();

    return (
      <Page className={className}>
        <PageBody>
          <PageBody.Title>인터뷰 대시보드</PageBody.Title>
          <PageBody.Body>
            <Tab
              aria-label="interview-dashboard-tab"
              items={[
                {
                  id: "home",
                  label: "홈",
                  panel: (
                    <QueryRenderer
                      refetchKey="InterviewDashboardPage"
                      query={InterviewDashboardPageHomeTabQueryDocument}
                      variables={{ groupName, interviewId }}
                    >
                      {({ data: { interviewTimes, interviewDashboard } }) => (
                        <div>
                          <InterviewDashboardSection
                            interviewDashboard={interviewDashboard}
                          />
                          <InterviewScheduleInlineSection
                            interviewTimes={interviewTimes}
                          />
                        </div>
                      )}
                    </QueryRenderer>
                  ),
                },

                {
                  id: "people",
                  label: "인원 관리",
                  panel: (
                    <QueryRenderer
                      refetchKey="InterviewDashboardPage"
                      variables={{ groupName, interviewId }}
                      query={InterviewDashboardPageQueryDocument}
                    >
                      {({
                        data: { interviewParticipants, interviewHosts },
                      }) => (
                        <div>
                          <Panel
                            to={`/group/@${groupName}/interview/${interviewId}/participants/invite`}
                            title="인터뷰 예약 안내 알림 전송하기"
                            content="인터뷰 대상자가 바인딩에서 인터뷰 시간을 예약할 수 있도록 카카오톡 메세지/메일을 전송해보세요!"
                            style={{ marginBottom: "1.2rem" }}
                          />
                          <CardSection>
                            <CardSection.Title
                              action={
                                <ClickableIcon
                                  icon={<Cog6ToothIcon width={20} />}
                                  onClick={() =>
                                    navigate(
                                      `/group/@${groupName}/interview/${interviewId}/participants/edit`
                                    )
                                  }
                                />
                              }
                            >
                              인터뷰 대상자 설정
                            </CardSection.Title>
                            <CardSection.Body>
                              <DataTable
                                noDataBody="인터뷰 대상자를 추가해주세요"
                                columns={[
                                  {
                                    id: "name",
                                    name: "이름",
                                    selector: (row) => row.name,
                                    sortable: true,
                                  },
                                  {
                                    id: "phoneNumber",
                                    name: "휴대폰 번호",
                                    selector: (row) => row.phoneNumber,
                                    sortable: true,
                                  },
                                  {
                                    id: "emailAddress",
                                    name: "이메일 주소",
                                    selector: (row) => row.emailAddress,
                                    sortable: true,
                                  },
                                  {
                                    id: "interviewReservationStatus",
                                    name: "인터뷰 예약 여부",
                                    cell: (row) =>
                                      row.hasReservedInterview ? (
                                        <Badge>예약 완료</Badge>
                                      ) : (
                                        <Badge variant="error">미예약</Badge>
                                      ),
                                  },
                                  {
                                    id: "moreButton",
                                    name: "",
                                    moreButton: true,
                                    cell: (row) => <MB participant={row} />,
                                  },
                                ]}
                                data={interviewParticipants}
                              />
                            </CardSection.Body>
                          </CardSection>
                          <CardSection>
                            {renderModal()}
                            <CardSection.Title
                              action={
                                <ClickableIcon
                                  icon={<Cog6ToothIcon width={20} />}
                                  onClick={showEditInterviewHostsModal}
                                />
                              }
                            >
                              면접관 설정
                            </CardSection.Title>
                            <CardSection.Body>
                              <DataTable
                                noDataBody="면접관을 추가해주세요"
                                columns={[
                                  {
                                    id: "username",
                                    name: "이름",
                                    selector: (row) => row.username,
                                    sortable: true,
                                  },
                                  {
                                    id: "phoneNumber",
                                    name: "휴대폰 번호",
                                    selector: (row) => row.phoneNumber,
                                    sortable: true,
                                  },
                                  {
                                    id: "emailAddress",
                                    name: "이메일 주소",
                                    selector: (row) => row.emailAddress,
                                    sortable: true,
                                  },
                                  {
                                    id: "moreButton",
                                    name: "",
                                    moreButton: true,
                                    cell: () => (
                                      <KebabMenu
                                        items={[
                                          {
                                            label: "삭제",
                                            onClick: () => {},
                                          },
                                        ]}
                                      />
                                    ),
                                  },
                                ]}
                                data={interviewHosts}
                              />
                            </CardSection.Body>
                          </CardSection>
                        </div>
                      )}
                    </QueryRenderer>
                  ),
                },
                {
                  id: "time",
                  label: "인터뷰 시간 관리",
                  panel: (
                    <QueryRenderer
                      refetchKey="InterviewDashboardPage"
                      query={InterviewDashboardPageSettingTabQueryDocument}
                      variables={{ groupName, interviewId }}
                    >
                      {({ data: { interview, interviewTimes }, refetch }) => (
                        <div>
                          <InterviewReservableTimeRangeSettingSection
                            interview={interview}
                          />
                          <InterviewTimeCandidatesSettingSection
                            interviewTimeCandidates={interviewTimes}
                          />
                        </div>
                      )}
                    </QueryRenderer>
                  ),
                },
                {
                  id: "settings",
                  label: "설정",
                  panel: (
                    <QueryRenderer
                      refetchKey="InterviewDashboardPage"
                      query={InterviewDashboardPageSettingTabQueryDocument}
                      variables={{ groupName, interviewId }}
                    >
                      {({ data: { interview, interviewTimes }, refetch }) => (
                        <div>
                          <Form
                            initialValues={{
                              groupName,
                              interviewId,
                              type: interview.type,
                              meetingURL: interview.meetingURL,
                              location: interview.location,
                            }}
                            mutation={EditInterviewTypeeDocument}
                            onComplete={() => {
                              refetch();
                              toast("인터뷰 설정 변경이 완료되었어요.");
                            }}
                          >
                            {({ values }) => (
                              <>
                                {/* FIXME: Improve tone */}
                                <Panel
                                  to={`/group/@${groupName}/setting/general`}
                                  title="연락망 등록하기"
                                  content="인터뷰 대상자가 인터뷰 관련 궁금증을 해결할 수 있는 창구를 등록해주세요!"
                                  style={{ marginBottom: "1.2rem" }}
                                />
                                <CardSection>
                                  <CardSection.Title>
                                    온/오프라인 설정
                                  </CardSection.Title>
                                  <CardSection.Body>
                                    <FlexCol gap="0.8rem">
                                      <RadioField
                                        name="type"
                                        items={[
                                          {
                                            label: "온라인",
                                            value: "ONLINE",
                                          },
                                          {
                                            label: "오프라인",
                                            value: "OFFLINE",
                                          },
                                        ]}
                                      />
                                      {values.type === EInterviewType.Online ? (
                                        <TextField
                                          fullWidth
                                          name="meetingURL"
                                          label="화상 회의 주소"
                                          placeholder="https://meet.google.com/ABCDEF"
                                          helperText="면접자에게 전달될 화상 회의 주소예요."
                                          inputProps={{
                                            maxLength: 1_000,
                                          }}
                                        />
                                      ) : (
                                        <TextField
                                          fullWidth
                                          name="location"
                                          label="면접 장소"
                                          placeholder="가나대학교 K관 512호"
                                          helperText="면접자에게 전달될 면접장 주소예요."
                                          inputProps={{
                                            maxLength: 80,
                                          }}
                                        />
                                      )}
                                    </FlexCol>
                                  </CardSection.Body>
                                  <CardSection.Footer>
                                    <SubmitButton children="저장" />
                                  </CardSection.Footer>
                                </CardSection>
                              </>
                            )}
                          </Form>
                        </div>
                      )}
                    </QueryRenderer>
                  ),
                },
              ]}
            />
          </PageBody.Body>
        </PageBody>
      </Page>
    );
  }
)`
  ${Tab} {
    flex-direction: column;
  }
`;
