import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import GroupListPage from "app/group/group-list-page";
import LoginPage from "app/login/login-page";
import { SignupPage } from "app/signup/signup-page";
import { InterviewListPage } from "app/interview/interview-list-page/interview-list-page";
import { InterviewParticipantsEditPage } from "app/interview/interview-participants-edit-page/interview-participants-edit-page";
import { InterviewDashboardPage } from "app/interview/interview-dashboard-page/interview-dashboard-page";
import { InterviewInvitationLoginPagePath } from "app/interview/interview-invitation/interview-invitation-login/interview-invitation-login-page-path";

import { NonUserParticipantMainPage } from "app/non-user-participant/non-user-participant-main-page";
import { NonUserParticipantInterviewReservationPage } from "app/non-user-participant/non-user-participant-interview-reservation-page";
import { GroupAdminInvitationAcceptPage } from "app/group/group-admin-invitation-accept/group-admin-invitation-accept-page";
import { useQueryString } from "common/query-string/hooks/use-query-string";
import { InterviewParticipantInvitationPage } from "app/interview/interview-participant-invitation-page/interview-participant-invitation-page";
import { GroupAdminManagePage } from "app/setting/admin/group-admin-manage-page";
import { ErrorPage } from "app/error/error-page";
import { GroupManagePage } from "app/setting/admin/group-manage-page";
import { InterviewModePage } from "app/interview/interview-mode-page/interview-mode-page";

const AnonymousUserLoginHandler = () => {
  const location = useLocation();
  const to = ["/", "/login"].includes(location.pathname)
    ? "/login"
    : `/login?next=${location.pathname}${location.search}`;
  return <Navigate to={to} />;
};

const UserLoginHandler = () => {
  const { search } = useQueryString<{ next?: string }>();
  const to = search["next"] || "/";

  return <Navigate to={to} />;
};

const ParamRedirect = ({ to }: { to: string }) => {
  const params = useParams();
  const path = Object.keys(params).reduce((path, param) => {
    return path.replace(`:${param}`, params[param] as string);
  }, to);

  return <Navigate to={path} />;
};

type routesType = {
  anonymous: React.ReactNode;
  user: React.ReactNode;
  nonUserParticipant: React.ReactNode;
};
const routes: routesType = {
  anonymous: (
    <Routes>
      <Route
        path="/interview-invitation"
        element={<InterviewInvitationLoginPagePath />}
      />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<AnonymousUserLoginHandler />} />
    </Routes>
  ),
  user: (
    <Routes>
      <Route
        path="/group-admin-invitation"
        element={<GroupAdminInvitationAcceptPage />}
      />
      <Route
        path="/group/:groupName"
        element={<ParamRedirect to="/group/:groupName/interview" />}
      />
      <Route
        path="/group/:groupName/interview"
        element={<InterviewListPage />}
      />
      <Route
        path="/group/:groupName/interview/:interviewId/participants/edit"
        element={<InterviewParticipantsEditPage />}
      />
      <Route
        path="/group/:groupName/interview/:interviewId"
        element={<InterviewDashboardPage />}
      />
      <Route
        path="/group/:groupName/interview/:interviewId/participant/:participantId"
        element={<InterviewModePage />}
      />
      <Route
        path="/group/:groupName/interview/:interviewId/participants/invite"
        element={<InterviewParticipantInvitationPage />}
      />
      <Route
        path="/group/:groupName/setting/general"
        element={<GroupManagePage />}
      />
      <Route
        path="/group/:groupName/setting/admin"
        element={<GroupAdminManagePage />}
      />
      <Route path="/group" element={<GroupListPage />} />
      <Route path="/login" element={<UserLoginHandler />} />
      <Route path="/" element={<ParamRedirect to="/group" />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  ),
  nonUserParticipant: (
    <Routes>
      <Route path="/" element={<NonUserParticipantMainPage />} />
      <Route
        path="/interview/reservation"
        element={<NonUserParticipantInterviewReservationPage />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  ),
};
export default routes;
