import styled from "styled-components";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

import Page from "common/page";
import { PageBody } from "common/page-body";
import { Table, TableBody, TableCell, TableHead, TableRow } from "common/table";
import { InlineSection } from "common/section";
import { FlexRow } from "common/flex";
import { ClickableIcon } from "common/clickable-icon";

import { initialize } from "./code-mirror";

const Header = styled(FlexRow)`
  justify-content: space-between;

  padding: 0 1.2rem 1.6rem;

  font-size: 1.4rem;
  font-weight: 600;
`;

export const InterviewModePage = () => {
  return (
    <Page>
      <PageBody>
        <PageBody.Title>인터뷰 모드</PageBody.Title>
        <PageBody.Body>
          <Header>
            <span>😊 이동주</span>
            <FlexRow gap="0.8rem" alignItems="center">
              <ClickableIcon
                icon={<ChevronLeftIcon width={24} />}
                onClick={() => {}}
              />
              <ClickableIcon
                icon={<ChevronRightIcon width={24} />}
                onClick={() => {}}
              />
            </FlexRow>
          </Header>
          <InlineSection>
            <InlineSection.Title>합/불 의견</InlineSection.Title>
            <InlineSection.Body>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>면접관</TableCell>
                    <TableCell>합/불</TableCell>
                    <TableCell>의견</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>이동주</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </InlineSection.Body>
          </InlineSection>
          <InlineSection>
            <InlineSection.Title>면접 노트</InlineSection.Title>
            <InlineSection.Body>
              <div
                id="editor"
                ref={(parent) => {
                  initialize(parent);
                }}
              />
            </InlineSection.Body>
          </InlineSection>
        </PageBody.Body>
      </PageBody>
    </Page>
  );
};
