import styled from "styled-components";

export const DangerPanel = styled.div`
  background: rgb(255 224 224);
  border: 1px solid rgb(249, 31, 31);
  border-radius: 4px;
  padding: 1.2rem;
  color: rgb(249, 31, 31);
  font-weight: 500;
`;
