import _ from "lodash";
import dayjs from "dayjs";
import styled, { css } from "styled-components";

import { Grid } from "common/grid";
import { Card } from "common/card";
import { CardSection } from "common/section";

import { InterviewDashboardPageHomeTabQueryQuery } from "gql-codegen/graphql";

const StatCardWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
`;
const StatCardMainText = styled.span`
  ${({ theme }) => theme.breakpoints.up("mobile")} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.breakpoints.down("mobile")} {
    font-size: 1.6rem;
  }
  font-weight: 600;
`;
const StatCardSubText = styled.span`
  ${({ theme }) => theme.breakpoints.down("mobile")} {
    font-size: 0.9rem;
  }
  font-weight: 500;
`;
const StatCard = styled(
  ({
    className,
    mainText,
    subText,
  }: {
    className?: string;
    variant: "a" | "b" | "c";
    mainText: React.ReactNode;
    subText: string;
  }) => {
    return (
      <Card className={className} direction="column">
        <StatCardMainText children={mainText} />
        <StatCardSubText children={subText} />
      </Card>
    );
  }
)`
  height: 7.2rem;
  padding: 0.8rem;

  flex-grow: 1;

  text-align: center;
  word-break: keep-all;

  ${({ variant }) => {
    if (variant === "a") {
      return css`
        background-color: rgb(232 234 246 / 1);
        color: rgb(40 53 147 / 1);
      `;
    }
    if (variant === "b") {
      return css`
        background-color: rgb(232 245 233 / 1);
        color: rgb(46 125 50 / 1);
      `;
    }
    if (variant === "c") {
      return css`
        background-color: #f4f8fa;
        // background-color: rgb(255 235 238 / 1);
        // color: rgb(216 27 96 / 1);
      `;
    }
  }}
`;

export const InterviewDashboardSection = ({
  interviewDashboard,
}: {
  interviewDashboard: InterviewDashboardPageHomeTabQueryQuery["interviewDashboard"];
}) => {
  const { interview, interviewHost, interviewParticipant } = interviewDashboard;
  return (
    <CardSection>
      <StatCardWrapper>
        <Grid gap="1.6rem" gridTemplateColumns="1fr 1fr">
          <StatCard
            variant="a"
            mainText={interview.upcoming}
            subText="다가오는 인터뷰"
          />
          <StatCard
            variant="b"
            mainText={interview.completed}
            subText="완료한 인터뷰"
          />
        </Grid>
        <Grid gap="1.6rem" gridTemplateColumns="1fr 1fr 1fr">
          <StatCard
            variant="c"
            mainText={
              <span>
                <span>{interviewParticipant.unReserved}</span>
                <span
                  style={{
                    fontSize: "1.1rem",
                    color: "gray",
                    marginLeft: "0.1rem",
                  }}
                >
                  /{interviewParticipant.total}
                </span>
              </span>
            }
            subText="예약하지 않은 면접자"
          />
          <StatCard
            variant="c"
            mainText={
              <span>
                <span>{interviewParticipant.reserved}</span>
                <span
                  style={{
                    fontSize: "1.1rem",
                    color: "gray",
                    marginLeft: "0.1rem",
                  }}
                >
                  /{interviewParticipant.total}
                </span>
              </span>
            }
            subText="예약완료한 면접자"
          />
          <StatCard
            variant="c"
            mainText={interviewHost.total}
            subText="면접관"
          />
        </Grid>
      </StatCardWrapper>
    </CardSection>
  );
};
