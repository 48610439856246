import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import styled, { css } from "styled-components";

import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/24/solid";

import { Calendar } from "common/calendar";
import { InlineSection } from "common/section";
import { NoData } from "common/no-data";
import { InterviewDashboardPageHomeTabQueryQuery } from "gql-codegen/graphql";

const CustomListItem = styled(ListItem)<{ disabled?: boolean }>`
  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;

        &:hover {
          background: rgb(0 0 0 / 6%);
        }
      `;
    }
  }}
`;

const OverflowScroller = styled.div``;
export const InterviewScheduleInlineSection = styled(
  ({
    className,
    interviewTimes,
  }: {
    className?: string;
    interviewTimes: InterviewDashboardPageHomeTabQueryQuery["interviewTimes"];
  }) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(
      interviewTimes[0]?.timestamp ?? dayjs()
    );

    const it = interviewTimes
      .filter(({ timestamp }) => timestamp.isSame(selectedDate, "day"))
      .map(({ timestamp, participant }) => ({
        disabled: !Boolean(participant),
        title: participant?.name ?? "예약자 없음",
        time: timestamp.format("a h:mm"),
      }));
    const hasReservableTime = it.length > 0;

    return (
      <InlineSection className={className}>
        <InlineSection.Title>스케쥴</InlineSection.Title>
        <InlineSection.Body
          style={!hasReservableTime ? { alignItems: "center" } : {}}
        >
          <Calendar
            value={selectedDate}
            onChange={(v, e) => setSelectedDate(v)}
            tileDisabled={({ activeStartDate, date, view }) =>
              !_.includes(
                _.map(interviewTimes, ({ timestamp }) =>
                  dayjs(timestamp).format("YYYY-MM-DD")
                ),
                dayjs(date).format("YYYY-MM-DD")
              )
            }
          />
          <OverflowScroller>
            <List
              className="py-0 mt-8 divide-y"
              style={{
                ...(!hasReservableTime ? { alignItems: "center" } : {}),
              }}
            >
              {!hasReservableTime ? (
                <NoData body="등록한 인터뷰 시간이 없어요." />
              ) : (
                it.map(({ disabled, title, time }, index) => (
                  <CustomListItem
                    key={title + time}
                    className="px-0"
                    disabled={disabled}
                  >
                    <ListItemText
                      primary={title}
                      secondary={
                        <span className="flex flex-col sm:flex-row sm:items-center mt-2 space-y-4 sm:space-y-0 sm:space-x-12">
                          <span className="flex items-center">
                            <ClockIcon
                              width="20px"
                              color="rgba(0, 0, 0, 0.26)"
                            />
                            <span className="mx-2 text-md text-gray-500">
                              {time}
                            </span>
                          </span>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <ChevronRightIcon
                        width="24px"
                        color="rgba(0, 0, 0, 0.54)"
                      />
                    </ListItemSecondaryAction>
                  </CustomListItem>
                ))
              )}
            </List>
          </OverflowScroller>
        </InlineSection.Body>
      </InlineSection>
    );
  }
)`
  ${InlineSection.Body} {
    display: flex;
    flex-wrap: wrap;
    gap: 2.8rem;

    padding: 0.8rem 0;
  }

  ${CustomListItem} {
    transition: background 0.2s ease;
    border-radius: 10px;
  }

  ${OverflowScroller} {
    ${({ theme }) => theme.flex.ible};
  }
`;
