import {
  ParamParseKey,
  PathMatch,
  PathPattern,
  matchPath as matchPathOg,
} from "react-router-dom";

export const matchPath = <
  ParamKey extends ParamParseKey<Path>,
  Path extends string
>(
  pattern: PathPattern<Path>,
  pathname: string
): PathMatch<ParamKey> | null => {
  const matched = matchPathOg<ParamKey, Path>(pattern, pathname);

  if (matched === null) {
    return null;
  }

  if ("groupName" in matched.params) {
    const groupName = matched.params.groupName as unknown as string;
    return {
      ...matched,
      params: {
        ...matched.params,
        groupName: groupName.replace("@", ""),
      },
    };
  }

  return matched;
};
