import { withScalars } from "apollo-link-scalars";
import { buildClientSchema } from "graphql";
import dayjs, { Dayjs } from "dayjs";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import introspectionResult from "gql-codegen/graphql.schema.json";

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    withScalars({
      schema: buildClientSchema(introspectionResult as any),
      typesMap: {
        Date: {
          serialize: (value: Dayjs) => value.toISOString(),
          parseValue: (value: string) => dayjs(value),
        },
      },
    }),
    new HttpLink({ uri: "/api/graphql" }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
  ]),
});
