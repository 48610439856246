import styled, { css } from "styled-components";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";

import { Card, CardContent } from "common/card";

type GroupCardProps = {
  onClick: () => void;
  groupName: string;
  className?: string;
};

const GroupName = styled.span`
  font-weight: 600;
`;

export const EmojiBox = styled.div`
  ${({ theme }) => theme.flex.cc};

  width: 3.6rem;
  height: 3.6rem;

  background-color: ${({ theme }) => theme.page.backgroundColor};

  font-size: 1.4rem;

  transition: all 0.3s ease 0s;

  ${({ theme }) => theme.borderRadius.defaultStyle};
`;
const EmojiPickerWrapper = styled.div``;
export const GroupCard = styled(
  ({ onClick, groupName, className }: GroupCardProps) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        {/* TODO: Support Group emoji custom feature */}
        {/* <EmojiPickerWrapper>
          <EmojiPicker open={open} />
        </EmojiPickerWrapper> */}
        <Card className={className} onClick={onClick}>
          <CardContent alignSelf="flex-end">
            <EmojiBox
              onClick={(e) => {
                e.stopPropagation();
                setOpen((o) => !o);
              }}
            >
              🎈
            </EmojiBox>
            <GroupName
              dangerouslySetInnerHTML={{
                __html: groupName.replace(/\n/g, "<br />"),
              }}
            />
          </CardContent>
        </Card>
      </>
    );
  }
)`
  transition: all 0.15s ease-in;

  ${({ theme }) => theme.breakpoints.up("mobile")} {
    width: 12rem;
    height: 12rem;
  }

  ${({ theme }) => theme.breakpoints.down("mobile")} {
    width: 100%;
    height: 8rem;
  }

  ${({ theme }) => theme.boxShadow.defaultStyle};
  ${({ theme }) => theme.borderRadius.defaultStyle};

  word-break: keep-all;

  &:hover {
    transform: scale(1.017);
  }

  ${CardContent} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ${({ theme }) =>
    css`
      color: ${theme.color.black};
    `};
`;
