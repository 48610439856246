import styled from "styled-components";
import { useDispatch } from "react-redux";

import { gql } from "@apollo/client";

import Page from "common/page";
import { PageBody } from "common/page-body";
import { useQuery } from "common/graphql/use-query";

import { setGroup } from "reducer/group-reducer";

import { GroupCard } from "../login/group-card";
import { AddGroupCard } from "./add-group-card";
import { ManagingGroupsQueryDocument } from "gql-codegen/graphql";

gql`
  query ManagingGroupsQuery {
    managingGroups {
      name
      fullName
    }
  }
`;

const CardGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  column-gap: 2.4rem;
  row-gap: 1.6rem;
`;

type GroupListPageProps = {
  className?: string;
};
const UnstyledGroupListPage = ({ className }: GroupListPageProps) => {
  const dispatch = useDispatch();
  const { data, loading, error, refetch } = useQuery(
    ManagingGroupsQueryDocument
  );
  if (loading) return null;

  const groups = data.managingGroups;

  return (
    <Page className={className}>
      <PageBody>
        <PageBody.Title>🏛️ 그룹목록</PageBody.Title>
        <CardGridWrapper>
          {groups.map((group) => (
            <GroupCard
              key={group.name}
              groupName={group.fullName}
              onClick={() => {
                dispatch(setGroup(group));
                window.location.href = `/group/@${group.name}`;
              }}
            />
          ))}
          <AddGroupCard refetchGroupList={refetch} />
        </CardGridWrapper>
      </PageBody>
    </Page>
  );
};
const GroupListPage = styled(UnstyledGroupListPage)``;
export default GroupListPage;
