import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button, TButtonProps } from "./button";

const ULinkButton = ({ to, ...props }: TButtonProps & { to: string }) => {
  return (
    <Link to={to}>
      <Button {...props} />
    </Link>
  );
};

export const LinkButton = styled(ULinkButton)``;
