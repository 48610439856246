import styled from "styled-components";

import { CardSection } from "common/section";
import { Calendar } from "common/calendar";

import { InterviewTimeAddSection } from "../interview-time-add-section";
import { InterviewTimeReviewSection } from "../interview-time-review-section";
import {
  InterviewTimeCandidatesContextProvider,
  useInterviewTimeCandidatesContext,
} from "./context/interview-time-candidates-context-provider";
import { InterviewTimeType } from "gql-codegen/graphql";

const CalendarWithContext = () => {
  const { currentDate, setCurrentDate } = useInterviewTimeCandidatesContext();
  return <Calendar value={currentDate} onChange={setCurrentDate} />;
};

export const InterviewTimeCandidatesSettingSection = styled(
  ({
    className,
    interviewTimeCandidates,
  }: {
    className?: string;
    interviewTimeCandidates: InterviewTimeType[];
  }) => {
    return (
      <InterviewTimeCandidatesContextProvider
        interviewTimeCandidates={interviewTimeCandidates}
      >
        <CardSection className={className}>
          <CardSection.Title>인터뷰 선택 가능 시간 설정</CardSection.Title>
          <CardSection.Body>
            <CalendarWithContext />
            <InterviewTimeAddSection />
            <InterviewTimeReviewSection />
          </CardSection.Body>
        </CardSection>
      </InterviewTimeCandidatesContextProvider>
    );
  }
)`
  ${CardSection.Body} {
    ${({ theme }) => theme.flex.col};
    gap: 2.4rem;
    padding: 0.8rem 0;
  }
`;
