import _, { Falsey } from "lodash";
import styled from "styled-components";
import { useState } from "react";

import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

import { ClickableIcon } from "common/clickable-icon";
import { MenuItem } from "common/menu/menu-item";
import { Menu } from "common/menu/menu";

type TKebabMenuItem =
  | {
      label: React.ReactNode;
      onClick: () => void;
    }
  | Falsey;
export const KebabMenu = styled(
  ({
    className,
    items: _items,
  }: {
    className?: string;
    width?: React.CSSProperties["width"];
    items: TKebabMenuItem[];
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const items = _.compact(_items);

    if (items.length === 0) {
      return null;
    }

    return (
      <div className={className}>
        <ClickableIcon
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          icon={<EllipsisVerticalIcon />}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {items.map(({ label, onClick }) => (
            <MenuItem
              key={label.toString()}
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
)`
  svg {
    width: ${(props) => props.width};
  }
`;

KebabMenu.defaultProps = {
  width: "1.4rem",
};
