import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Box,
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
} from "@mui/material";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export const CardActionArea = styled(MuiCardActionArea)`
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;

  word-break: keep-all;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;
const Content = styled.div`
  font-weight: 400;
  font-size: 0.9rem;
`;

const ChevronRight = styled(ChevronRightIcon)`
  margin-left: 1.2rem;
`;

const UPanel = ({
  className,
  to,
  title,
  content,
  ...htmlAttrs
}: {
  className?: string;
  to?: string;
  title: React.ReactNode;
  content: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const disableRipple = !Boolean(htmlAttrs.onClick || to);
  return (
    <MuiCard
      className={className}
      component={Box}
      {...htmlAttrs}
      onClick={(e) => {
        htmlAttrs.onClick?.(e);
      }}
    >
      {to ? (
        <Link to={to} style={{ flex: 1 }}>
          <CardActionArea disableRipple={disableRipple}>
            <div>
              <Title>{title}</Title>
              <Content>{content}</Content>
            </div>
            <ChevronRight width="24px" color="white" />
          </CardActionArea>
        </Link>
      ) : (
        <CardActionArea disableRipple={disableRipple}>
          <Title>{title}</Title>
          <Content>{content}</Content>
        </CardActionArea>
      )}
    </MuiCard>
  );
};

export const Panel = styled(UPanel)`
  ${({ theme }) => theme.boxShadow.defaultStyle};
  ${({ theme }) => theme.borderRadius.defaultStyle};

  background: #556372;
  color: white;
`;
