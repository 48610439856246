import { gql, useMutation } from "@apollo/client";

import { DeleteGroupAdminInvitationDocument } from "gql-codegen/graphql";

gql`
  mutation DeleteGroupAdminInvitation(
    $groupName: String!
    $inviteeEmail: String!
  ) {
    deleteGroupAdminInvitation(
      groupName: $groupName
      inviteeEmail: $inviteeEmail
    ) {
      ok
    }
  }
`;

export const useDeleteGroupAdminInvitationMutation = () => {
  return useMutation(DeleteGroupAdminInvitationDocument);
};
