import styled from "styled-components";
import { useCookies } from "react-cookie";

import Form from "common/form";
import SubmitButton from "common/submit-button";
import { CountDown } from "common/countdown";
import { NumberField } from "common/number-field";
import { useQueryString } from "common/query-string/hooks/use-query-string";
import { PageBody } from "common/page-body";

import { LoginPageLayout } from "app/login/login-page-layout";
import {
  ENonUserParticipantVerificiationType,
  VerifyNonUserParticipantVerificationNumberDocument,
} from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";

export const InterviewInvitationPhoneVerificationPage = styled(
  ({ className, phoneNumber }: { className?: string; phoneNumber: string }) => {
    const { search } = useQueryString<{ token: string }>();
    const [_cookies, setCookie] = useCookies();
    const toast = useToast();
    return (
      <LoginPageLayout className={className}>
        <PageBody>
          <PageBody.Title>💬 휴대폰 인증</PageBody.Title>
          <div>
            {phoneNumber}로 전송된 인증번호 6자리를 3분 안에 입력해주세요.
          </div>
          <CountDown initial={60 * 3} />
          <Form
            initialValues={{
              token: search["token"],
              verificationType: ENonUserParticipantVerificiationType.Phone,
              verificationNumber: "",
              phoneNumber,
            }}
            mutation={VerifyNonUserParticipantVerificationNumberDocument}
            validate={({ verificationNumber }) => {
              if (!verificationNumber) {
                return { verificationNumber: "인증번호를 입력해주세요." };
              }
            }}
            onComplete={() => {
              setCookie("token", search["token"]);
              toast("인증이 완료되었어요.");
              window.location.assign("/");
            }}
          >
            {() => (
              <>
                <NumberField
                  name="verificationNumber"
                  placeholder="인증번호 입력"
                  maxLength={6}
                />
                <SubmitButton children="인증 완료" />
              </>
            )}
          </Form>
        </PageBody>
      </LoginPageLayout>
    );
  }
)`
  ${PageBody} {
    gap: unset;
    ${CountDown} {
      margin-left: auto;
      font-size: 0.9rem;
      color: ${({ theme }) => theme.gray[700]};
    }
  }

  ${SubmitButton} {
    margin-top: 1.2rem;
  }
`;
