import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";

import { gql } from "@apollo/client";

import { PageBody } from "common/page-body";
import { QueryRenderer } from "common/graphql/query-renderer";
import { Card, CardContent } from "common/card";
import { CardSection } from "common/section";
import { Badge } from "common/badge";
import { FlexRow } from "common/flex";

import { useAuthContext } from "auth/context";
import {
  EInterviewType,
  NonUserParticipantMainPageQueryDocument,
} from "gql-codegen/graphql";

import CalendarOrange from "../../res/images/calendar-orange.svg";
import GoogleMeet from "../../res/images/google-meet.png";
import Message from "../../res/images/message.svg";
import { NonUserParticipantPage } from "./non-user-participant-page";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

gql`
  query NonUserParticipantMainPageQuery {
    nonUserParticipantQuery {
      group {
        name
        pfKakaoURL
      }
      interview {
        id
        title
        type
        meetingURL
        location
        reservableTimeFrom
        reservableTimeTo
      }
      interviewTime {
        timestamp
      }
    }
  }
`;

const CustomCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
`;
const CustomCard = styled(Card).attrs({
  sx: { minWidth: 275 },
})`
  width: 100%;
`;

const UnstyledGoogleMeetLogo = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <img src={GoogleMeet} alt="google-meet" />
    </div>
  );
};
const GoogleMeetLogo = styled(UnstyledGoogleMeetLogo)`
  width: 3.6rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const UnstyledCalendarOutlineLogo = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <img src={CalendarOrange} alt="calendar-orange" />
    </div>
  );
};
const CalendarOutlineLogo = styled(UnstyledCalendarOutlineLogo)`
  width: 3.6rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const UnstyledMessageLogo = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <img src={Message} alt="message" />
    </div>
  );
};
const MessageLogo = styled(UnstyledMessageLogo)`
  width: 3.6rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const GrayText = styled.div`
  color: gray;
  font-size: 0.8rem;
  margin-top: 0.1rem;
`;

const NoteCard = styled(CardSection)`
  background-color: hsl(44.31deg 67.26% 90.38%);
  width: 22rem;

  padding: 1.6rem;
  margin-bottom: 1.6rem;

  ${CardSection.Title} {
    font-size: 1.2rem;
  }

  ${CardSection.Body} {
    margin-top: 1.2rem;
  }
`;

const HighlightedUnderline = styled.span`
  border-bottom: 4px solid rgba(223, 245, 38, 0.58);
`;
const InterviewCreationInfo = styled.div`
  color: gray;
  font-size: 0.9rem;
`;
export const NonUserParticipantMainPage = styled(
  ({ className }: { className?: string }) => {
    const {
      nonUserParticipant: { name },
    } = useAuthContext();
    const navigate = useNavigate();

    const verboseWhen = (time: Dayjs) => {
      if (time.isTomorrow()) {
        return "내일";
      }
      if (time.isToday()) {
        return "오늘";
      }

      const today = dayjs();
      const todayDay = today.day();
      const lastDayOfTheWeek = today.add(6 - todayDay, "day").endOf("day");
      const lastDayOfTheNextWeek = lastDayOfTheWeek.add(1, "week");

      const dayNum = time.day();
      const dayVerbose = (n: number) => {
        switch (n) {
          case 0:
            return "일";
          case 1:
            return "월";
          case 2:
            return "화";
          case 3:
            return "수";
          case 4:
            return "목";
          case 5:
            return "금";
          case 6:
            return "토";
        }
      };

      if (lastDayOfTheWeek.isAfter(time)) {
        return `이번 주 ${dayVerbose(dayNum)}요일에`;
      }
      if (
        lastDayOfTheWeek.isBefore(time) &&
        lastDayOfTheNextWeek.isAfter(time)
      ) {
        return `다음 주 ${dayVerbose(dayNum)}에`;
      }

      // TODO: ㅜㅜㅜㅜㅜ
      return "조만간";
    };

    return (
      <NonUserParticipantPage className={className}>
        <QueryRenderer
          variables={{}}
          query={NonUserParticipantMainPageQueryDocument}
        >
          {({
            data: {
              nonUserParticipantQuery: { group, interview, interviewTime },
            },
          }) => (
            <PageBody>
              <PageBody.Title>
                안녕하세요, {name}님 😎
                <br />
                {interviewTime ? (
                  <>
                    <HighlightedUnderline>
                      {verboseWhen(interviewTime.timestamp)}
                    </HighlightedUnderline>{" "}
                    인터뷰가 진행된다는 사실, 잊지 않으셨죠?
                  </>
                ) : (
                  <>
                    <HighlightedUnderline>
                      {interview.title}
                    </HighlightedUnderline>{" "}
                    인터뷰 시간을 예약해볼까요?
                  </>
                )}
              </PageBody.Title>
              <PageBody.Body>
                <NoteCard>
                  <CardSection.Title>📌</CardSection.Title>
                  <CardSection.Body>
                    {/* <div>
                      <CardSection.Caption>주최자</CardSection.Caption>
                      {group.name}
                    </div> */}
                    {interview.type === EInterviewType.Online ? (
                      <FlexRow
                        flexWrap="wrap"
                        columnGap="2.4rem"
                        rowGap="1.2rem"
                      >
                        <div>
                          <CardSection.Caption>인터뷰 시간</CardSection.Caption>
                          {interviewTime ? (
                            <>
                              {interviewTime.timestamp.format("YYYY년 M월 D일")}
                              <br />
                              {interviewTime.timestamp.format("a h시 mm분")}
                            </>
                          ) : (
                            <Badge variant="info" size="small">
                              정보없음
                            </Badge>
                          )}
                        </div>
                        <div>
                          <CardSection.Caption>인터뷰 장소</CardSection.Caption>
                          <Badge style={{ backgroundColor: "#15b950" }}>
                            온라인
                          </Badge>
                        </div>
                      </FlexRow>
                    ) : (
                      <FlexRow flexWrap="wrap" gap="0 2.4rem">
                        <div>
                          <CardSection.Caption>인터뷰 시간</CardSection.Caption>
                          {interviewTime ? (
                            <span>
                              {interviewTime.timestamp.format("YYYY년 M월 D일")}
                              <br />
                              {interviewTime.timestamp.format("a h시 mm분")}
                            </span>
                          ) : (
                            <Badge variant="info" size="small">
                              정보없음
                            </Badge>
                          )}
                        </div>
                        <div>
                          <CardSection.Caption>인터뷰 장소</CardSection.Caption>
                          {interview.location}
                        </div>
                      </FlexRow>
                    )}
                  </CardSection.Body>
                </NoteCard>
                <CustomCard onClick={() => navigate("/interview/reservation")}>
                  <CustomCardContent>
                    <div>
                      <span>인터뷰 시간 신청/변경</span>
                      <br />
                      <GrayText>
                        <span>
                          시작:{" "}
                          {interview.reservableTimeFrom.format(
                            "M월 D일 a h시 mm분"
                          )}
                        </span>
                        <br />
                        <span>
                          마감:{" "}
                          {interview.reservableTimeTo.format(
                            "M월 D일 a h시 mm분"
                          )}
                        </span>
                      </GrayText>
                    </div>
                    <CalendarOutlineLogo />
                  </CustomCardContent>
                </CustomCard>
                {interview.type === EInterviewType.Online ? (
                  <CustomCard
                    sx={{ minWidth: 275 }}
                    onClick={() => {
                      window.open(interview.meetingURL, "_blank");
                    }}
                  >
                    <CustomCardContent>
                      <span>면접실 입장하기</span>
                      <GoogleMeetLogo />
                    </CustomCardContent>
                  </CustomCard>
                ) : // TODO: 오프라인 면접 장소 보여주기
                null}
                <CustomCard
                  sx={{ minWidth: 275 }}
                  onClick={() => {
                    window.open(group.pfKakaoURL, "_blank");
                  }}
                >
                  <CustomCardContent>
                    <div>
                      <span>문의하기</span>
                      <br />
                      <GrayText>
                        <span>
                          원하는 시간이 없을 경우
                          <br />
                          문의주세요!
                        </span>
                      </GrayText>
                    </div>
                    <MessageLogo />
                  </CustomCardContent>
                </CustomCard>
              </PageBody.Body>
              <InterviewCreationInfo>
                * 본 인터뷰는 {group.name} 관리자가 생성하였습니다.
              </InterviewCreationInfo>
            </PageBody>
          )}
        </QueryRenderer>
      </NonUserParticipantPage>
    );
  }
)`
  ${PageBody.Body} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 1.2rem;

    margin-bottom: 2rem;
  }
  ${CardSection.Caption} {
    margin-bottom: 0.2rem;
  }
`;
