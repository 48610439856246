import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  EnvelopeIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";

import Form from "common/form";
import SubmitButton from "common/submit-button";
import TextField from "common/text-field";
import { useQueryString } from "common/query-string/hooks/use-query-string";
import { FlexCol } from "common/flex";

import { LoginDocument } from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";

gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ok
    }
  }
`;

const LoginFieldBox = styled(FlexCol)``;
export const LoginForm = styled(
  ({
    className,
    onComplete,
  }: {
    className?: string;
    onComplete?: () => void;
  }) => {
    const [clicked, setClicked] = useState(true);
    const toast = useToast();
    const { search } = useQueryString<{ next?: string }>();

    const next = search["next"] || "/";

    return (
      <Form
        className={className}
        mutation={LoginDocument}
        validate={(values) => {
          const errors = {} as any;
          return errors;
        }}
        initialValues={{
          username: "",
          password: "",
        }}
        onError={(errors) => {
          if (errors instanceof Error && errors.message === "Unauthorized") {
            toast("아이디와 비밀번호를 다시 확인해주세요.", "error");
          } else {
            toast("예상치 못한 오류가 발생했어요.", "error");
          }
        }}
        onComplete={
          onComplete ??
          ((response, errors) => {
            window.location.assign(next);
          })
        }
      >
        {() => {
          return (
            <LoginFieldBox>
              <TextField
                name="username"
                label={{ icon: UserIcon, text: "사용자명" }}
                autoComplete="username"
                autoFocus
                validate={(values) => !values && "아이디를 입력해주세요."}
              />
              <TextField
                name="password"
                type="password"
                label={{ icon: LockClosedIcon, text: "비밀번호" }}
                autoComplete="current-password"
                validate={(values) => !values && "비밀번호를 입력해주세요."}
              />
              <SubmitButton
                children={clicked ? "로그인" : "시작하기"}
                variant="contained"
                onClick={clicked ? null : () => setClicked(true)}
                withFormError={false}
              />
              <Link
                to={`/signup?next=${next}`}
                style={{
                  marginTop: "0.8rem",
                  marginRight: "0.8rem",
                  textAlign: "right",
                  color: "#666666",
                  fontSize: "0.8rem",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                회원가입하기
              </Link>
            </LoginFieldBox>
          );
        }}
      </Form>
    );
  }
)`
  ${LoginFieldBox} {
    min-width: 280px;

    ${TextField} {
      margin-top: 0.8rem;
    }
    ${SubmitButton} {
      margin-top: 1.2rem;
    }
  }
`;
