import React from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Form as FormikForm, Formik, FormikHelpers } from "formik";
import { FieldNode, OperationDefinitionNode } from "graphql";

import { ApolloError, TypedDocumentNode, useMutation } from "@apollo/client";

import useProgress from "./progress/use-progress";

export const DEFAULT_FORM_ERROR_KEY = "_";

export type TFormChildrenArgs<TResult = any, TVariables = any> = {
  data?: TResult;
  error?: ApolloError;
  loading?: boolean;
  values: TVariables;
  setFieldValue?: (
    field: keyof TVariables,
    value: any,
    shouldValidate?: boolean
  ) => void;
  formError: any;
};
export type TFormProps<TResult = any, TVariables = any> = {
  className?: string;
  enableReinitialize?: boolean;
  initialValues: { [k in keyof TVariables]: TVariables[k] | undefined };
  sanitize?: (values: TVariables) => any;
  validate?: (values: TVariables) => void;
  validateOnChange?: boolean;
  children: (props: TFormChildrenArgs<TResult, TVariables>) => React.ReactNode;
  onComplete?: (response: TResult, error: any) => void;
  onError?: (error: any) => void;
  disabledEnterSubmit?: boolean;

  mutation?: TypedDocumentNode<TResult, TVariables>;
  variables?: TVariables;
};
function UnstyledForm<TResult, TVariables>({
  className,
  children,
  sanitize,
  validate,
  enableReinitialize = false,
  initialValues,
  onComplete,
  onError,
  disabledEnterSubmit = false,

  mutation,
  variables,
  ...props
}: TFormProps<TResult, TVariables>) {
  const showProgress = useProgress();
  const csrftoken = Cookies.get("csrftoken");
  const [mutate, { data, error, loading }] = useMutation(mutation);

  const handleSubmit = async (
    variables: TVariables,
    formikHelpers: FormikHelpers<TVariables>
  ) => {
    showProgress(true);
    let response, error;

    const getMutationName = (mutation: TypedDocumentNode) => {
      const toCamelCase = (str: string) => str[0].toLowerCase() + str.slice(1);
      const name = (
        (mutation.definitions[0] as OperationDefinitionNode).selectionSet
          .selections[0] as FieldNode
      ).name.value;
      return toCamelCase(name) ?? "";
    };

    try {
      sanitize?.(variables);

      const { data, errors } = await mutate({ variables });
      const mutationName = getMutationName(mutation);

      response = data;
      console.log(data);
      error = errors;

      if (data[mutationName]?.ok === true) {
        if (typeof onComplete === "function") {
          onComplete(response, error);
        } else {
          if (typeof onError === "function") {
            onError(error);
          }
        }
      }

      if (data[mutationName]?.ok === false) {
        Object.entries(data[mutationName]?.errors ?? {}).forEach(
          ([key, value]) => {
            if (typeof value === "string") {
              formikHelpers.setFieldError(key, value);
            }
          }
        );
      }

      if (errors && errors.length > 0) {
        formikHelpers.setFieldError(DEFAULT_FORM_ERROR_KEY, errors[0].message);
      }
    } catch (err) {
      error = err;
      console.error(err);

      formikHelpers.setFieldError(DEFAULT_FORM_ERROR_KEY, String(err));

      if (typeof onError === "function") {
        onError(err);
        return;
      }
    } finally {
      showProgress(false);
    }
  };

  const handleValidation = (values: TVariables) => {
    return validate?.(values);
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validate={handleValidation}
      onSubmit={handleSubmit}
      {...props}
    >
      {(formikProps) => (
        <FormikForm
          className={className}
          // onKeyDown={(e) => {
          //   if (
          //     !disabledEnterSubmit &&
          //     // @ts-ignore
          //     e.target.type !== "button" &&
          //     e.key === "Enter"
          //   ) {
          //     handleSubmit(formikProps.values, formikProps);
          //   }
          // }}
          onSubmit={formikProps.handleSubmit}
        >
          {children({
            data,
            error,
            loading,
            values: formikProps.values,
            setFieldValue:
              formikProps.setFieldValue as TFormChildrenArgs["setFieldValue"],
            formError: formikProps.errors,
            // submitForm: formikProps.submitForm,
          })}
        </FormikForm>
      )}
    </Formik>
  );
}
const Form = styled(UnstyledForm)`` as <TResult = any, TVariables = any>(
  props: TFormProps<TResult, TVariables>
) => React.ReactElement;

export default Form;
