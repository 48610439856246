import styled from "styled-components";

export const EmptyStatus = styled.div`
  ${({ theme }) => theme.flex.cc};

  height: 21rem;

  background: #f6f4f4;
  color: #656565;

  font-size: 1.2rem;
`;
