import React from "react";
import styled, { StyledComponent } from "styled-components";

import { THTMLDivElementAttributes } from "./typings/html-element.types";

const Actions = styled.div``;
const UPageBodyTitle = ({
  children,
  actions,
  ...htmlAttrs
}: THTMLDivElementAttributes & {
  children: React.ReactNode;
  actions?: React.ReactNode[];
}) => (
  <div {...htmlAttrs}>
    <div>{children}</div>
    {actions ? (
      <Actions>
        {actions?.map((action, i) => (
          <div key={i}>{action}</div>
        ))}
      </Actions>
    ) : null}
  </div>
);
export const PageBodyTitle = styled(UPageBodyTitle)`
  ${({ theme }) => theme.breakpoints.up("mobile")} {
    font-size: 1.6rem;

    margin-bottom: ${({ theme }) => theme.page.title.margin.primary.bottom};
  }

  ${({ theme }) => theme.breakpoints.down("mobile")} {
    font-size: 1.2rem;

    margin-right: ${({ theme }) => theme.page.title.margin.mobile.left};
    margin-bottom: ${({ theme }) => theme.page.title.margin.mobile.bottom};
    margin-left: ${({ theme }) => theme.page.title.margin.mobile.left};
  }

  display: flex;

  font-weight: 700;

  ${Actions} {
    margin-left: auto;
  }
`;

const PageBodyBody = styled(({ children, className }) => (
  <div className={className} children={children} />
))`
  height: auto;
`;

// prettier-ignore
export const PageBody = styled.div(({ theme }) => `
  max-width: ${theme.page.maxWidth};
  height: auto;
  min-height: ${theme.vh(100)};

  background-color: ${theme.page.backgroundColor};

  margin: 0 auto;
  ${theme.breakpoints.up("mobile")} {
    padding: ${theme.page.padding.primary.top} ${theme.page.padding.primary.left};
  }

  ${theme.breakpoints.down("mobile")} {
    padding: ${theme.page.padding.mobile.top} ${theme.page.padding.mobile.left};
  }


  ${theme.flex.ible};
`
) as StyledComponent<"div", any, {}, never> & {
  Title: typeof PageBodyTitle;
  Body: typeof PageBodyBody;
};

PageBody.Title = PageBodyTitle;
PageBody.Body = PageBodyBody;
