import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";

import { gql, useMutation } from "@apollo/client";

import { useQueryString } from "common/query-string/hooks/use-query-string";
import { QueryRenderer } from "common/graphql/query-renderer";

import {
  AcceptGroupAdminInvitationDocument,
  GroupAdminInvitationAcceptPageQueryDocument,
} from "gql-codegen/graphql";

gql`
  mutation AcceptGroupAdminInvitation($token: String!) {
    acceptGroupAdminInvitation(token: $token) {
      ok
      group {
        fullName
      }
      errors {
        _
      }
    }
  }
`;

const UserHandler = () => {
  const { search } = useQueryString<{ token: string }>();
  const [acceptInvitation] = useMutation(AcceptGroupAdminInvitationDocument);

  useEffect(() => {
    acceptInvitation({
      variables: {
        token: search["token"],
      },
    }).then(
      ({
        data: {
          acceptGroupAdminInvitation: { ok, group, errors },
        },
      }) => {
        if (ok) {
          // TODO: 문구 개선 및 모달로 변경
          alert(`${group.fullName} 관리자가 되신 것을 축하드려요!`);
        } else if (errors) {
          alert(errors._);
        }

        window.location.href = "/";
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

gql`
  query GroupAdminInvitationAcceptPageQuery($token: String!) {
    isGroupAdminInvitationAvailable(token: $token)
  }
`;

export const GroupAdminInvitationAcceptPage = styled(
  ({ className }: { className?: string }) => {
    const { search } = useQueryString<{ token: string }>();

    if (!search["token"]) {
      alert("잘못된 접근입니다.");
      window.location.replace("/");
      return <></>;
    }

    return (
      <QueryRenderer
        variables={{
          token: search["token"],
        }}
        query={GroupAdminInvitationAcceptPageQueryDocument}
      >
        {({ data: { isGroupAdminInvitationAvailable } }) => {
          if (!isGroupAdminInvitationAvailable) {
            alert("초대가 만료되었거나 잘못된 링크입니다.");
            return <Navigate to="/" replace />;
          }

          return <UserHandler />;
        }}
      </QueryRenderer>
    );
  }
)``;
