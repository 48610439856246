import styled from "styled-components";

import type { THTMLDivElementAttributes } from "./typings/html-element.types";

const Emoji = styled.div``;
const Text = styled.div``;
export const NoData = styled(
  ({
    body = "데이터가 없어요",
    ...props
  }: THTMLDivElementAttributes & {
    body?: React.ReactNode;
  }) => {
    return (
      <div {...props}>
        <Emoji>🫠</Emoji>
        <Text>{body}</Text>
      </div>
    );
  }
)`
  ${({ theme }) => theme.flex.col};
  align-items: center;

  padding: 2.4rem 0;

  opacity: 0.8;

  ${Emoji} {
    font-size: 1.4rem;
    filter: grayscale(100%);
  }
  ${Text} {
    font-weight: 400;
    color: #a0aec0;
  }
`;
