import "lodash.product";
import _ from "lodash";
import styled from "styled-components";
import dayjs from "dayjs";

import { Button } from "common/button";
import { SubSection } from "common/section";
import { Join } from "common/join";
import { Space } from "common/space";

import {
  InterviewTimeCandidatesStore,
  useInterviewTimeCandidatesContext,
} from "./interview-time-candidates-setting-section/context/interview-time-candidates-context-provider";

const ButtonRowWrap = styled.div``;
const HighlightedText = styled.span``;
export const InterviewTimeAddSection = styled(
  ({ className }: { className?: string }) => {
    const { currentDate, setCurrentDate } = useInterviewTimeCandidatesContext();
    const dispatch = InterviewTimeCandidatesStore.dispatch;

    const handleTimeDeltaClick = (
      operator: "+" | "-",
      time: `${string}분` | `${string}시간`
    ) => {
      setCurrentDate((prev) =>
        prev.add(
          Number(`${operator}${time.replace(/[^0-9]/g, "")}`),
          time.replace(/[0-9]/g, "") === "분" ? "m" : "h"
        )
      );
    };

    const addClick = () => {
      const toAdd = dayjs(currentDate, "YYYY-MM-DD a h시 mm분");

      dispatch({
        type: "CREATE_INTERVIEW_TIME_CANDIDATE",
        payload: {
          timestamp: toAdd,
        },
      });
    };

    return (
      <SubSection className={className}>
        <SubSection.Title>
          <Join
            children={[
              currentDate.format("M월 DD일"),
              <Space />,
              <HighlightedText>
                {currentDate.format("a h시 m분")}
              </HighlightedText>,
              "을 인터뷰 시간으로 추가",
            ]}
          />
        </SubSection.Title>
        {_.chunk(
          _.product(
            ["+", "-"],
            ["10분", "15분", "20분", "30분", "1시간", "2시간", "3시간", "6시간"]
          ),
          8
        ).map((chunks) => (
          <ButtonRowWrap>
            {chunks.map(
              ([op, time]: ["+" | "-", `${string}분` | `${string}시간`]) => (
                <Button
                  variant="text"
                  children={`${op}${time}`}
                  onClick={() => handleTimeDeltaClick(op, time)}
                />
              )
            )}
          </ButtonRowWrap>
        ))}
        <Button variant="contained" children="추가" onClick={addClick} />
      </SubSection>
    );
  }
)`
  ${ButtonRowWrap} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  ${HighlightedText} {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 600;
  }
`;
