import { AsYouType } from "libphonenumber-js";
import { useState } from "react";
import styled from "styled-components";

import { gql } from "@apollo/client";

import { DataTable } from "common/data-table";
import { validateEmail } from "common/email-field";
import Form from "common/form";
import { QueryRenderer } from "common/graphql/query-renderer";
import Page from "common/page";
import { PageBody } from "common/page-body";
import { useParams } from "common/react-router-dom/use-params";
import { InlineSection, SubSection } from "common/section";
import { TextFieldBase } from "common/text-field";
import SubmitButton from "common/submit-button";
import { validatePhoneNumber } from "common/phone-num-field";
import useToast from "common/toast/use-toast";

import {
  EditInterviewParticipantInputType,
  EditInterviewParticipantsDocument,
  InterviewParticipantsEditPageQueryDocument,
} from "gql-codegen/graphql";

gql`
  query InterviewParticipantsEditPageQuery(
    $groupName: String!
    $interviewId: ID!
  ) {
    interviewParticipants(groupName: $groupName, interviewId: $interviewId) {
      id
      name
      phoneNumber
      emailAddress
    }
  }
`;

gql`
  mutation EditInterviewParticipants(
    $groupName: String!
    $interviewId: ID!
    $participants: [EditInterviewParticipantInputType!]!
  ) {
    editInterviewParticipants(
      groupName: $groupName
      interviewId: $interviewId
      participants: $participants
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const InterviewParticipantsEditPage = styled(() => {
  const { groupName, interviewId } = useParams<{
    groupName: string;
    interviewId: string;
  }>();
  const toast = useToast();
  const [text, setText] = useState("");

  const parseText = (text: string): EditInterviewParticipantInputType[] => {
    const textToArray = text.split("\n").map((e) =>
      e
        .split(/(\s+)/)
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim())
    );

    if (text === "") return [];

    return textToArray.map(([name, emailAddress, phone]) => ({
      name,
      emailAddress,
      phoneNumber:
        phone && typeof phone === "string"
          ? new AsYouType("KR").input(phone)
          : "",
    }));
  };

  return (
    <Page>
      <QueryRenderer
        variables={{ groupName, interviewId }}
        query={InterviewParticipantsEditPageQueryDocument}
      >
        {({ data: { interviewParticipants } }) => (
          <PageBody>
            <PageBody.Title>인터뷰 대상자 신규 추가하기</PageBody.Title>
            <PageBody.Body>
              {/* TODO: Support Bulk delete */}
              {/* <InlineSection>
                <InlineSection.Title>
                  인터뷰 대상자 삭제하기
                </InlineSection.Title>
                <Form
                  // mutation={gql`
                  //   mutation DeleteInterviewParticipants(
                  //     $groupName: String!
                  //     $interviewId: ID!
                  //     $participantIds: [ID!]!
                  //   ) {
                  //     deleteInterviewParticipants(
                  //       groupName: $groupName
                  //       interviewId: $interviewId
                  //       participantIds: $participantIds
                  //     ) {
                  //       ok
                  //       errors {
                  //         _
                  //       }
                  //     }
                  //   }
                  // `}
                  initialValues={{
                    groupName,
                    interviewId,
                    participantIds: [],
                  }}
                  onComplete={() => {}}
                >
                  {({ values }) => (
                    <>
                      <InlineSection.Body>
                        <DataTable
                          columns={[
                            {
                              id: "checkbox",
                              checkbox: true,
                              cell: (row, index) => (
                                <CheckboxField<string>
                                  name={`participantIds[${index}]`}
                                  defaultChecked={values.participantIds?.includes(
                                    row.id
                                  )}
                                  checkedValue={row.id}
                                />
                              ),
                            },
                            {
                              id: "name",
                              name: "이름",
                              selector: (row) => row.name,
                            },
                            {
                              id: "phoneNumber",
                              name: "휴대폰 번호",
                              selector: (row) => row.phoneNumber,
                            },
                            {
                              id: "emailAddress",
                              name: "이메일 주소",
                              selector: (row) => row.emailAddress,
                            },
                            {
                              id: "hasReservedInterview",
                              name: "인터뷰 예약 여부",
                              cell: (row) =>
                                // TODO: query interview reservation status
                                true ? (
                                  <Badge>예약완료</Badge>
                                ) : (
                                  <Badge variant="error">예약안함</Badge>
                                ),
                            },
                          ]}
                          data={interviewParticipants}
                        />
                      </InlineSection.Body>
                      <InlineSection.Footer>
                        <SubmitButton children="삭제" />
                      </InlineSection.Footer>
                    </>
                  )}
                </Form>
              </InlineSection> */}
              <Form
                initialValues={{
                  groupName,
                  interviewId,
                  participants: [],
                }}
                mutation={EditInterviewParticipantsDocument}
                validateOnChange={false}
                validate={(values) => {
                  if (values.participants.length === 0) {
                    return {
                      _: "인터뷰 대상자를 추가해주세요.",
                    };
                  }

                  const wrongEmailAddresses = values.participants
                    .filter(({ emailAddress }) => {
                      return !validateEmail(emailAddress);
                    })
                    .map(({ emailAddress }) => emailAddress);
                  if (wrongEmailAddresses.length > 0) {
                    return {
                      _: `${wrongEmailAddresses.join(
                        ", "
                      )} 이메일 형식이 올바르지 않아요`,
                    };
                  }

                  const wrongPhoneNumbers = values.participants
                    .filter(({ phoneNumber }) => {
                      return !validatePhoneNumber(phoneNumber);
                    })
                    .map(({ phoneNumber }) => phoneNumber);
                  if (wrongPhoneNumbers.length > 0) {
                    return {
                      _: `휴대폰 번호 형식이 올바르지 않아요.\n${wrongPhoneNumbers.join(
                        "\n"
                      )}`,
                    };
                  }
                }}
                onComplete={() => {
                  toast(`인터뷰 대상자가 정상적으로 추가되었어요.`);
                  window.location.replace(
                    `/group/@${groupName}/interview/${interviewId}?id=people`
                  );
                }}
                disabledEnterSubmit
              >
                {({ values, formError, setFieldValue }) => (
                  <InlineSection>
                    <InlineSection.Title>
                      인터뷰 대상자 신규 추가하기
                    </InlineSection.Title>
                    <InlineSection.Body>
                      <SubSection>
                        <SubSection.Title>
                          이름, 이메일, 휴대폰 번호를 스프레드 시트에서 그대로
                          복사해서 붙여넣어주세요.
                        </SubSection.Title>
                        <SubSection.Body>
                          <TextFieldBase
                            placeholder="신정원	one@leed.at	01000000000&#10;남우현	woo@leed.at	01011111111&#10;이우식	sik@leed.at	01022222222"
                            variant="filled"
                            label="사용자"
                            value={text}
                            onChange={({ target }) => {
                              setText(target.value);
                              setFieldValue(
                                "participants",
                                parseText(target.value)
                              );
                            }}
                            inputProps={{
                              maxLength: 100_000,
                            }}
                            fullWidth
                            multiline
                          />
                        </SubSection.Body>
                      </SubSection>
                      <SubSection>
                        <SubSection.Title>
                          아래 정보가 정확한지 확인한 후에 추가하기 버튼을
                          눌러주세요.
                        </SubSection.Title>
                        <SubSection.Body>
                          <DataTable
                            noDataBody="면접자 정보를 입력해주세요"
                            columns={[
                              {
                                id: "name",
                                name: "이름",
                                selector: (row) => row.name,
                                sortable: true,
                              },
                              {
                                id: "emailAddress",
                                name: "이메일 주소",
                                selector: (row) => row.emailAddress,
                                sortable: true,
                              },
                              {
                                id: "phoneNumber",
                                name: "휴대폰 번호",
                                selector: (row) => row.phoneNumber,
                                sortable: true,
                              },
                            ]}
                            data={values.participants}
                          />
                        </SubSection.Body>
                      </SubSection>
                    </InlineSection.Body>
                    <InlineSection.Footer>
                      <SubmitButton children="추가하기" />
                    </InlineSection.Footer>
                  </InlineSection>
                )}
              </Form>
            </PageBody.Body>
          </PageBody>
        )}
      </QueryRenderer>
    </Page>
  );
})``;
