import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { Tooltip } from "@mui/material";

import { Button } from "common/button";
import { SubSection } from "common/section";
import { NoData } from "common/no-data";

import {
  InterviewTimeCandidatesStore,
  useInterviewTimeCandidatesContext,
} from "./interview-time-candidates-setting-section/context/interview-time-candidates-context-provider";

const InterviewTimeList = styled.div``;
export const InterviewTimeReviewSection = styled(
  ({ className = "" }: { className?: string }) => {
    const { currentDate, interviewTimeCandidates } =
      useInterviewTimeCandidatesContext();
    const dispatch = InterviewTimeCandidatesStore.dispatch;

    const deleteInterviewTime = (timestamp: Dayjs) => {
      dispatch({
        type: "DELETE_INTERVIEW_TIME_CANDIDATE",
        payload: {
          timestamp,
        },
      });
    };

    const candidatesForSelectedDate = interviewTimeCandidates.filter(
      ({ timestamp }) =>
        timestamp.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD")
    );

    return (
      <SubSection className={className}>
        <SubSection.Title>
          아래의 시간들이 면접자가 {currentDate.format("M월 D일")}에 인터뷰를
          선택할 수 있는 시간이 되어요.
        </SubSection.Title>
        {candidatesForSelectedDate.length === 0 ? (
          <NoData
            body={`${currentDate.format(
              "M월 D일"
            )}에는 등록된 면접 시간이 없어요.`}
          />
        ) : (
          <InterviewTimeList>
            {candidatesForSelectedDate.map((v) => (
              <Tooltip
                title={
                  v.deletable
                    ? "클릭하여 제거"
                    : "이미 해당 시간에 예약한 유저가 있어, 제거할 수 없어요."
                }
              >
                <span>
                  <Button
                    variant="text"
                    disabled={!v.deletable}
                    children={v.timestamp.format("a h시 mm분")}
                    onClick={() => deleteInterviewTime(v.timestamp)}
                  />
                </span>
              </Tooltip>
            ))}
          </InterviewTimeList>
        )}
      </SubSection>
    );
  }
)`
  ${InterviewTimeList} {
    padding: 0 0.4rem;
    gap: 1rem;
  }
`;
