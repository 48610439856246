import _ from "lodash";
import { FC } from "react";
import { useField } from "formik";
import styled from "styled-components";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { DEFAULT_FORM_ERROR_KEY } from "./form";

export type FormErrorProps = {
  className?: string;
  /**
   * Field name
   */
  name?: string;
};

export const UnstyledFormError: FC<FormErrorProps> = ({
  children,
  className,
  name,
}) => {
  let [, { error }] = useField(name || DEFAULT_FORM_ERROR_KEY);

  // For backward compatibility, `children` has higher priority.
  error = (children || error) as any;

  if (_.isEmpty(error)) return null;

  return (
    <div className={className}>
      <InformationCircleIcon width={14} />
      {error}
    </div>
  );
};

export const FormError = styled(UnstyledFormError)`
  ${({ theme }) => theme.flex.cc};
  text-align: center;

  max-width: 24rem;
  padding: 1rem 0;
  margin: 0 auto 1rem;
  border-radius: 0.4rem;

  font-size: 0.9rem;
  color: red;
  background-color: #ff00001f;

  white-space: pre-wrap;

  animation: 0.612s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal none running
    shake;

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(1px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-1px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(1px, 0, 0);
    }
  }

  svg {
    margin-right: 0.4rem;
  }
`;
