import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  ArrowTopRightOnSquareIcon,
  EnvelopeIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { FormControlLabel, lighten } from "@mui/material";
import { gql } from "@apollo/client";

import { PageBody } from "common/page-body";
import TextField from "common/text-field";
import Form from "common/form";
import PhoneNumField from "common/phone-num-field";
import SubmitButton from "common/submit-button";
import { EmailField } from "common/email-field";
import { useQueryString } from "common/query-string/hooks/use-query-string";

import useToast from "common/toast/use-toast";
import { SignupDocument } from "gql-codegen/graphql";
import { LoginPageLayout } from "app/login/login-page-layout";
import { CheckboxField } from "common/checkbox-field";
import { FlexRow } from "common/flex";
import { _customTheme } from "theme/mui-theme";

gql`
  mutation Signup(
    $username: String!
    $password: String!
    $phoneNumber: String!
    $email: String!
  ) {
    signup(
      username: $username
      password: $password
      phoneNumber: $phoneNumber
      email: $email
    ) {
      ok
      errors {
        _
        username
      }
    }
  }
`;

const Logo = styled.span`
  font-size: 1.6rem;
`;
const CatchPhrase = styled.div`
  margin-bottom: 1.6rem;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const PrimaryColor = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
`;
const PrivacyPolicyInfo = styled.div`
  padding: 1rem;
  margin-top: 0.4rem;

  background: ${({ theme }) => lighten(theme.palette.primary.main, 0.9)};

  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 12px;

  font-size: 0.9rem;
`;
export const SignupPage = styled(({ className }: { className?: string }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { search } = useQueryString<{ next?: string }>();
  return (
    <LoginPageLayout className={className}>
      <PageBody>
        <Logo>👋</Logo>
        <CatchPhrase>
          지금 회원가입하고
          <br />
          바인딩의 모든 기능을 이용해보세요!
        </CatchPhrase>
        <Form
          initialValues={{
            username: "",
            password: "",
            // @ts-expect-error: 'rePassword' is used internally in form
            rePassword: "",
            phoneNumber: "",
            email: "",
            agreementToPrivacyPolicy: false,
          }}
          validate={(values) => {
            // @ts-expect-error: 'agreementToPrivacyPolicy' is used internally in form
            if (!values.agreementToPrivacyPolicy) {
              return { _: "개인정보 수집 및 이용에 동의해주세요." };
            }
            return {};
          }}
          mutation={SignupDocument}
          onComplete={({ signup: { ok, errors } }, err) => {
            if (ok) {
              toast("회원가입이 완료되었어요.");
              navigate(search["next"] ? search["next"] : "/");
            } else if (errors) {
              toast(errors._, "error");
            }
          }}
        >
          {({ values, formError }) => (
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <TextField
                      name="username"
                      label={{ icon: UserIcon, text: "사용자명" }}
                      validate={(username) => {
                        if (!username) {
                          return "사용자명을 입력해주세요.";
                        }
                        if (username.length < 5) {
                          return "5자 이상의 사용자명을 입력해주세요.";
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      name="password"
                      type="password"
                      label={{ icon: LockClosedIcon, text: "비밀번호" }}
                      inputProps={{ maxLength: 16 }}
                      validate={(password) => {
                        if (!password) {
                          return "비밀번호를 입력해주세요.";
                        }
                        if (password.length < 9) {
                          return "9자 이상의 비밀번호를 입력해주세요.";
                        }
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      name="rePassword"
                      type="password"
                      label={{ icon: LockClosedIcon, text: "비밀번호 확인" }}
                      inputProps={{ maxLength: 16 }}
                      validate={(rePassword) => {
                        if (!rePassword) {
                          return "비밀번호를 확인해주세요.";
                        }
                        if (values.password !== rePassword) {
                          return "비밀번호가 일치하지 않아요.";
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <PhoneNumField
                      name="phoneNumber"
                      validate={(phoneNumber) => {
                        if (!phoneNumber) {
                          return "휴대폰 번호를 입력해주세요.";
                        }
                      }}
                      label={{ icon: PhoneIcon, text: "휴대폰 번호" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <EmailField
                      name="email"
                      label={{ icon: EnvelopeIcon, text: "이메일 주소" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <CheckboxField<boolean>
                          name="agreementToPrivacyPolicy"
                          defaultChecked={false}
                          checkedValue={true}
                        />
                      }
                      label={
                        <FlexRow
                          justifyContent="space-between"
                          alignItems="center"
                          gap=".4rem"
                        >
                          <div>
                            <PrimaryColor>[필수]</PrimaryColor>{" "}
                            <span>개인정보 수집 및 이용 동의</span>
                          </div>
                          <a
                            target="_blank"
                            href="https://plip.kr/pcc/4eeac96e-611f-4047-976b-4bf0efb149ff/privacy/1.html"
                            style={{
                              textDecoration: "underline",
                              color: _customTheme.gray[700],
                            }}
                          >
                            <FlexRow
                              alignItems="center"
                              style={{ fontSize: ".8rem" }}
                            >
                              전문
                              <ArrowTopRightOnSquareIcon width=".8rem" />
                            </FlexRow>
                          </a>
                        </FlexRow>
                      }
                    />
                    <PrivacyPolicyInfo>
                      바인딩(Binding)에서는 개인정보보호법에 따라 개인정보 수집
                      및 이용에 대한 동의를 받고자 합니다. 개인정보처리방침
                      전문에는 개인정보 수집 항목, 처리목적, 보유기간, 처리 위탁
                      기관, 개인정보 취급 거부권 등의 내용을 다루고 있으니
                      충분히 정독하신 후에 회원가입 진행 부탁드립니다.
                    </PrivacyPolicyInfo>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <SubmitButton>가입하기</SubmitButton>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </Form>
      </PageBody>
    </LoginPageLayout>
  );
})`
  table {
    width: 100%;

    td:not(:first-child) {
      ${TextField} {
        width: calc(100% - 0.4rem);
        margin-left: 0.4rem;
      }
    }
  }

  ${PageBody} {
    gap: unset;
  }

  ${TextField}, ${PhoneNumField} {
    width: 100%;

    margin-bottom: 0.4rem;
  }

  ${SubmitButton} {
    margin-top: 1.2rem;
  }
`;
