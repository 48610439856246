import dayjs from "dayjs";

import { gql } from "@apollo/client";

import DateTimePicker from "common/date-time-picker";
import { useModalForm } from "common/modal/hooks/use-modal-form";
import { SelectField } from "common/select-field";
import SubmitButton from "common/submit-button";
import { Table, TableBody, TableCell, TableRow } from "common/table";
import TextField from "common/text-field";
import { ModalEventEmitter } from "common/modal/modal";
import { queryFetcher } from "common/graphql/use-query";
import { useParams } from "common/react-router-dom/use-params";

import useToast from "common/toast/use-toast";
import { CreateInterviewDocument, EInterviewType } from "gql-codegen/graphql";

gql`
  mutation CreateInterview(
    $groupName: String!
    $interview: CreateInterviewInputType!
  ) {
    createInterview(groupName: $groupName, interview: $interview) {
      ok
    }
  }
`;

export const useCreateInterviewModalForm = () => {
  const toast = useToast();
  const { groupName } = useParams<{ groupName: string }>();

  return useModalForm({
    header: <>인터뷰 생성</>,
    body: ({ values }) => (
      <>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">제목</TableCell>
              <TableCell>
                <TextField
                  name="interview.title"
                  placeholder="23 Winter 신입 학회원 모집"
                  validate={(value) => {
                    if (!value) {
                      return "제목을 입력해주세요.";
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">유형</TableCell>
              <TableCell>
                <SelectField
                  label="유형"
                  name="interview.type"
                  items={[
                    { label: "온라인", value: "ONLINE" },
                    { label: "오프라인", value: "OFFLINE" },
                  ]}
                  validate={(value) => {
                    if (!value) {
                      return "유형을 선택해주세요.";
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">오픈 시간</TableCell>
              <TableCell>
                <DateTimePicker
                  name="interview.reservableTimeFrom"
                  label="오픈 시간"
                  validate={(reservableTimeFrom) => {
                    if (!reservableTimeFrom) {
                      return "오픈 시간을 입력해주세요.";
                    }
                    if (
                      reservableTimeFrom.isAfter(
                        values.interview.reservableTimeTo
                      )
                    ) {
                      return "오픈 시간은 현재 시간 이후여야 해요.";
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">마감 시간</TableCell>
              <TableCell>
                <DateTimePicker
                  name="interview.reservableTimeTo"
                  label="마감 시간"
                  validate={(reservableTimeTo) => {
                    if (!reservableTimeTo) {
                      return "마감 시간을 입력해주세요.";
                    }
                    if (
                      reservableTimeTo.isBefore(
                        values.interview.reservableTimeFrom
                      )
                    ) {
                      return "마감 시간은 오픈 시간 이후여야 해요.";
                    }
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    ),
    footer: <SubmitButton>생성</SubmitButton>,
    formProps: {
      initialValues: {
        groupName,
        interview: {
          title: "",
          type: EInterviewType.Online,
          reservableTimeFrom: dayjs(),
          reservableTimeTo: dayjs(),
        },
      },
      mutation: CreateInterviewDocument,
      onComplete: () => {
        ModalEventEmitter.emit("close");
        toast("인터뷰가 생성되었어요.");
        queryFetcher.emit("InterviewListPage");
      },
    },
  });
};
