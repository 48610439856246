import React, { createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { gql } from "@apollo/client";

import { useQuery } from "common/graphql/use-query";

import { login as loginUser } from "../reducer/user-reducer";
import { login as loginNonUserParticipant } from "reducer/non-user-participant-reducer";
import {
  NonUserParticipantQueryDocument,
  UserQueryDocument,
} from "gql-codegen/graphql";

gql`
  query UserQuery {
    user {
      id
      username
      phoneNumber
    }
  }
`;

gql`
  query NonUserParticipantQuery {
    nonUserParticipant {
      id
      name
      phoneNumber
      emailAddress
    }
  }
`;

export const AuthContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const dispatch = useDispatch();

  const userQuery = useQuery(UserQueryDocument, {
    redirectMainOnUnauthorized: false,
  });
  const nonUserParticipantQuery = useQuery(NonUserParticipantQueryDocument, {
    redirectMainOnUnauthorized: false,
  });

  const loading = userQuery.loading || nonUserParticipantQuery.loading;
  const refetch = async () => {
    await userQuery.refetch();
    await nonUserParticipantQuery.refetch();
  };

  const context: AuthContextType = {
    nonUserParticipant:
      nonUserParticipantQuery.data?.nonUserParticipant ?? null,
    user: userQuery.data?.user ?? null,
    refetch,
  };

  useEffect(() => {
    if (context.user) {
      dispatch(loginUser(context.user));
    }
    if (context.nonUserParticipant) {
      dispatch(loginNonUserParticipant(context.nonUserParticipant));
    }
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null;

  return <AuthContext.Provider value={context} children={children} />;
};

export type AuthContextType = {
  nonUserParticipant: {
    id: string;
    name: string;
    phoneNumber: string;
    emailAddress: string;
  } | null;
  user: {
    id: string;
    username: string;
    phoneNumber: string;
  } | null;
  refetch: () => Promise<any>;
};

const AuthContext = createContext<AuthContextType>(null as any);
export default AuthContext;

export const useAuthContext = () => useContext(AuthContext);
