import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import styled from "styled-components";

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldPropsType,
} from "@mui/material";
import { FlexRow } from "./flex";

type TCustomLabel = {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  text: string;
};
export type TextFieldProps = Omit<MuiTextFieldPropsType, "variant" | "label"> &
  Omit<JSX.IntrinsicElements["input"], "size" | "ref" | "label"> & {
    variant?: MuiTextFieldPropsType["variant"];
    name: string;
    validate?: (values: string) => string;
    label?: React.ReactNode | TCustomLabel;
  };

function UnstyledTextField(props: TextFieldProps) {
  const [error, setError] = useState("");

  const formikArgs = _.pick(props, ["name", "validate", "onChange", "value"]);
  const [field, meta] = useField<string>(formikArgs);

  useEffect(() => {
    meta.touched && setError(meta.error || "");
  }, [meta]);

  return (
    <TextFieldBase
      error={!!error}
      helperText={error || ""}
      {..._.omit(props, ["validate"])}
      // {...props}
      {...field}
    />
  );
}
const TextField = styled(UnstyledTextField)``;
export default TextField;

export type TextFieldBaseProps = Omit<MuiTextFieldPropsType, "variant"> & {
  variant?: MuiTextFieldPropsType["variant"];
  label?: React.ReactNode | TCustomLabel;
};

function UnstyledTextFieldBase({
  variant = "outlined",
  inputProps,
  ...props
}: TextFieldBaseProps) {
  const [label, setLabel] = useState<React.ReactNode>(undefined);

  useEffect(() => {
    if (!props.label) return;

    const isLabelObj = (a): a is TCustomLabel => {
      return typeof a === "object" && "icon" in a && "text" in a;
    };

    if (isLabelObj(props.label)) {
      const Icon = props.label.icon;
      setLabel(
        <FlexRow gap="0.4rem" alignItems="center">
          <Icon width="1.2rem" />
          {props.label.text}
        </FlexRow>
      );
    } else {
      setLabel(props.label);
    }
  }, [props.label]);

  return (
    <MuiTextField
      variant={variant}
      inputProps={{ maxLength: 20, ...inputProps }}
      label={label}
      {..._.omit(props, ["label"])}
    />
  );
}
export const TextFieldBase = styled(UnstyledTextFieldBase)`
  * {
    font-family: unset;
  }
  input {
    border-radius: 0.4rem;
  }

  width: 100%;
`;
