import LogRocket from "logrocket";
import moment from "moment";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { nest, withProps } from "recompose";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";

import AuthContext, { AuthContextProvider } from "./auth/context.tsx";
import { apolloClient } from "./common/graphql/apollo-client.ts";
import ProgressProvider from "./common/progress/progress-provider.tsx";
import ToastProvider from "./common/toast/toast-provider.tsx";
import { GroupContextProvider } from "./packages/group/group-context.tsx";
import { store } from "./reducer/store";
import { RouterContextProvider } from "./router/context.tsx";
import routes from "./router/routes.tsx";
import { theme } from "./theme/mui-theme.tsx";

import "dayjs/locale/ko";
import "moment/locale/ko";
import "./global.css";
import "./fonts.css";

import dayjs from "dayjs";
const el = document.createElement("div", { id: "eruda" });
document.body.appendChild(el);

Sentry.init({
  environment: process.env.BINDING_ENV,
  dsn: "https://ac22744af0eaf1509c57c465012efa83@o4507556578852864.ingest.us.sentry.io/4507996316499968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/binding\.at\/api\/graphql/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Provider = nest(
  withProps({ store })(ReduxProvider),
  withProps({ theme })(SCThemeProvider),
  withProps({ theme })(MuiThemeProvider),
  withProps({ client: apolloClient })(ApolloProvider),
  withProps({ routes })(RouterContextProvider),
  AuthContextProvider,
  BrowserRouter,
  ProgressProvider,
  ToastProvider,
  withProps({ adapterLocale: "ko", dateAdapter: AdapterDayjs })(
    LocalizationProvider
  )
);

dayjs.locale("ko");
moment.locale("ko");
if (process.env.NODE_ENV === "production") {
  LogRocket.init("zxsoci/binding");
}

const RootElement = () => {
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Provider>
      <LocalizationProvider
        dateFormats={{
          meridiem: "AM",
        }}
      />
      <AuthContext.Consumer>
        {({ user, nonUserParticipant }) => {
          if (user) {
            return <GroupContextProvider>{routes.user}</GroupContextProvider>;
          }

          if (nonUserParticipant) {
            return routes.nonUserParticipant;
          }

          return routes.anonymous;
        }}
      </AuthContext.Consumer>
    </Provider>
  );
};

ReactDOM.render(<RootElement />, document.getElementById("root"));
