import styled from "styled-components";

import MuiMenuItem from "@mui/material/MenuItem";

export const MenuItem = styled(MuiMenuItem)`
  min-height: 38px;
  padding: 6px 12px;

  font-size: 0.9rem;
`;
