import styled from "styled-components";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableCellProps as MuiTableCellProps,
  TableBodyProps as MuiTableBodyProps,
  TableHeadProps as MuiTableHeadProps,
  TableProps as MuiTableProps,
  TableRowProps as MuiTableRowProps,
} from "@mui/material";

const UnstyledTable = ({ className = "", ...props }: MuiTableProps) => (
  <div className={className}>
    <MuiTable {...props} />
  </div>
);
export const Table = styled(UnstyledTable)`
  overflow-x: auto;
  white-space: nowrap;
`;
const UnstyledTableHead = (props: MuiTableHeadProps) => (
  <MuiTableHead {...props} />
);
export const TableHead = styled(UnstyledTableHead)``;
const UnstyledTableBody = (props: MuiTableBodyProps) => (
  <MuiTableBody {...props} />
);
export const TableBody = styled(UnstyledTableBody)``;
const UnstyledTableCell = ({ ...props }: MuiTableCellProps) => (
  <MuiTableCell {...props} />
);
export const TableCell = styled(UnstyledTableCell)`
  .css-1ygcj2i-MuiTableCell-root {
    padding: 1.6rem 1.2rem;
  }
`;
const UnstyledTableRow = (props: MuiTableRowProps) => (
  <MuiTableRow {...props} />
);
export const TableRow = styled(UnstyledTableRow)``;
