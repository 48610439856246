import { createSlice } from "@reduxjs/toolkit";

export type NonUserParticipantState = {
  id: string;
  name: string;
  phoneNumber: string;
  emailAddress: string;
};

const initialState: NonUserParticipantState = {
  id: "",
  name: "",
  phoneNumber: "",
  emailAddress: "",
};

type LoginActionType = {
  type: string;
  payload: NonUserParticipantState;
};

export const nonUserParticipantSlice = createSlice({
  name: "nonUserParticipant",
  initialState,
  reducers: {
    login(state, action: LoginActionType) {
      if (!action.payload) return;
      return action.payload;
    },
  },
});

export const { login } = nonUserParticipantSlice.actions;

export default nonUserParticipantSlice.reducer;
