import styled from "styled-components";
import Helmet from "react-helmet";

import { NavigationBar } from "./navigation-bar/navigation-bar";
import { Footer } from "./footer";

const Children = styled.div`
  flex: 1;
`;
function UnstyledPage({
  className,
  title,
  showNav,
  showFooter,
  children,
}: {
  className?: string;
  title?: string;
  showNav?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
}) {
  return (
    <>
      <div className={className}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {showNav && <NavigationBar />}
        <Children>
          {children}
          {showFooter && <Footer />}
        </Children>
      </div>
    </>
  );
}

// prettier-ignore
const Page = styled(UnstyledPage)(({ theme,showNav }) => `
  ${theme.breakpoints.down("mobile")} {
    ${showNav && `
      padding-top: ${theme.navigationBar.slim.height};
    `}
  }
  ${theme.breakpoints.up("mobile")} {
    ${showNav && `
      padding-left: ${theme.navigationBar.primary.width};
    `}
  }

  height: auto;
  min-height: ${theme.vh(100)};

  background-color: ${theme.page.backgroundColor};
`
);

Page.defaultProps = {
  showNav: true,
  showFooter: true,
};

export default Page;
