import styled from "styled-components";

import { FlexRow } from "common/flex";
import type { THTMLDivElementAttributes } from "common/typings/html-element.types";

const InlineSectionTitle = styled(
  ({
    children,
    action,
    ...props
  }: THTMLDivElementAttributes & {
    action?: React.ReactNode;
  }) => {
    return (
      <div {...props}>
        {children}
        {action ? (
          <>
            <FlexRow flex={1} />
            {action}
          </>
        ) : null}
      </div>
    );
  }
)`
  display: flex;
  align-items: center;

  font-size: 1.2rem;
  font-weight: 600;
`;

const InlineSectionBody = styled.div`
  margin-top: 2.4rem;
`;
const InlineSectionFooter = styled.div`
  margin-top: 2.4rem;
`;

const _UInlineSection = (props: THTMLDivElementAttributes) => {
  return <div {...props} />;
};

const _InlineSection = styled(_UInlineSection)`
  padding: 1.2rem 1.6rem;
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.borderRadius.defaultStyle};

  background: white;
  box-shadow: 5px 8px 12px -9px rgba(0, 0, 0, 0.1);

  & + & {
    border-bottom: 1px solid lightgray;
  }
`;
export const InlineSection = _InlineSection as typeof _InlineSection & {
  Title: typeof InlineSectionTitle;
  Body: typeof InlineSectionBody;
  Footer: typeof InlineSectionFooter;
};
InlineSection.Title = InlineSectionTitle;
InlineSection.Body = InlineSectionBody;
InlineSection.Footer = InlineSectionFooter;
