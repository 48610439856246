import styled, { css } from "styled-components";
import { forwardRef } from "react";

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

type TButtonSize = "small" | "medium" | "large";

export type TButtonProps = TButtonBaseProps;
type TButtonBaseProps = MuiButtonProps & {
  size?: TButtonSize;
};

const UnstyledButton = forwardRef<HTMLButtonElement, TButtonBaseProps>(
  (props, ref) => {
    return <ButtonBase ref={ref} {...props} />;
  }
);
export const Button = styled(UnstyledButton)``;

const UnstyledButtonBase = forwardRef<HTMLButtonElement, TButtonBaseProps>(
  (props, ref) => {
    return (
      <MuiButton ref={ref} variant="contained" color="primary" {...props} />
    );
  }
);

export const ButtonBase = styled(UnstyledButtonBase)`
  font-family: unset;

  box-shadow: none;
  border-radius: 0.4rem;

  ${({ size }) => {
    switch (size) {
      case "large":
        return css`
          width: 100%;
          height: 3.6rem;
        `;
    }
  }}
`;
