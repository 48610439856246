export const Join = ({
  separator,
  children,
}: {
  separator?: React.ReactNode;
  children: React.ReactNode[];
}) => {
  return (
    <>
      {children.map((child, i) => {
        if (i !== 0 && separator) {
          return (
            <>
              {separator}
              {child}
            </>
          );
        }
        return child;
      })}
    </>
  );
};
