import React from "react";
import styled from "styled-components";

const Logo = styled.span`
  font-size: 24px;
`;
const USlimNavigationBar = ({
  className,
  icon,
}: {
  className?: string;
  icon: {
    left?: React.ReactNode;
    right?: React.ReactNode;
  };
}) => {
  return (
    <header className={className}>
      {icon?.left ?? <Logo style={{ visibility: "hidden" }}>👋</Logo>}
      <Logo>👋</Logo>
      {icon?.right ?? <Logo style={{ visibility: "hidden" }}>👋</Logo>}
    </header>
  );
};
export const SlimNavigationBar = styled(USlimNavigationBar)`
  width: 100%;
  height: ${({ theme }) => theme.navigationBar.slim.height};
  padding: 0 16px;

  position: fixed;
  top: 0;

  ${({ theme }) => theme.flex.row};
  justify-content: space-between;
  align-items: center;

  background-color: rgb(244, 248, 250);

  z-index: ${({ theme }) => theme.zIndex.navigationBar};
`;
