import styled from "styled-components";

import Page from "common/page";
import { PageBody } from "common/page-body";
import { Button } from "common/button";

import { GroupAdminListSection } from "./group-admin-list-section";
import { useInviteGroupAdminModalForm } from "./hooks/use-invite-group-admin-modal-form";

export const GroupAdminManagePage = styled(
  ({ className }: { className?: string }) => {
    const {
      renderModal: renderAddGroupAdminModal,
      show: showAddGroupAdminModal,
    } = useInviteGroupAdminModalForm();
    return (
      <Page className={className}>
        <PageBody>
          <PageBody.Title
            actions={[
              <Button variant="contained" onClick={showAddGroupAdminModal}>
                관리자 초대
              </Button>,
            ]}
          >
            관리자 목록
          </PageBody.Title>
          <PageBody.Body>
            {renderAddGroupAdminModal()}
            <GroupAdminListSection />
          </PageBody.Body>
        </PageBody>
      </Page>
    );
  }
)``;
