import { createElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { matchPath } from "../react-router-dom/match-path";
import { AnimatedOpener } from "../animation";
import { _customTheme } from "theme/mui-theme";

const FlexGrow = styled.div`
  flex-grow: 1;
`;
const SubItemsWrap = styled.div``;
const Title = styled.span``;

type TNavigationBarItem = {
  className?: string;
  title: string;
  to?: string;
  onClick?: () => void;
  Icon?: React.ForwardRefExoticComponent<any>;

  subItems?: TNavigationBarItem[];
  isSubItem?: boolean;

  styleParentActive?: boolean;
  styleChildActive?: boolean;
};

const withSelected = (Component: typeof UNavigationBarItem) => {
  return (props: TNavigationBarItem) => {
    const { subItems, to } = props;

    const styleChildActive = (() => {
      if (to) {
        const matched = matchPath(
          {
            path: to,
            end: false,
          },
          window.location.pathname
        );

        return Boolean(matched);
      }
    })();

    const styleParentActive = (() => {
      if (Array.isArray(subItems)) {
        const pathMatchedItem = subItems.find((item) => {
          return item.to
            ? matchPath(
                {
                  path: item.to,
                  end: false,
                },
                window.location.pathname
              )
            : null;
        });
        return Boolean(pathMatchedItem);
      }
    })();

    return (
      <Component
        {...props}
        styleParentActive={styleParentActive}
        styleChildActive={styleChildActive}
      />
    );
  };
};

const UNavigationBarItem = ({
  className,
  title,
  to,
  onClick,
  Icon,
  subItems = [],
  styleParentActive,
}: TNavigationBarItem) => {
  const [open, setOpen] = useState(styleParentActive ?? false);
  const navigate = useNavigate();

  const hasSubItems = subItems.length > 0;

  return (
    <>
      {createElement(hasSubItems ? "button" : "a", {
        className,
        role: "button",
        onClick: hasSubItems
          ? () => setOpen((p) => !p)
          : onClick || (() => navigate(to)),
        children: (
          <>
            {Icon && (
              <Icon className="nav-icon" color={_customTheme.gray[500]} />
            )}
            <Title>{title}</Title>
            {hasSubItems && (
              <>
                <FlexGrow />
                <ChevronDownIcon
                  width={14}
                  color={_customTheme.gray[800]}
                  style={{
                    transition: "transform 0.1s ease 0s",
                    ...(open
                      ? {}
                      : {
                          transform: "rotate(-90deg)",
                        }),
                  }}
                />
              </>
            )}
          </>
        ),
      })}
      {hasSubItems && (
        <AnimatedOpener open={open} onClose={() => setOpen(false)}>
          <SubItemsWrap>
            {subItems.map((item) => {
              return <NavigationBarItem key={item.title} {...item} isSubItem />;
            })}
          </SubItemsWrap>
        </AnimatedOpener>
      )}
    </>
  );
};

export const NavigationBarItem = withSelected(
  styled(UNavigationBarItem)`
    // Reset 'button' default styles
    background: unset;
    border: none;

    display: flex;
    align-items: center;

    ${({ theme }) => theme.borderRadius.defaultStyle};

    padding: 0 1rem;
    height: 2.5rem;

    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.gray[800]};

    --title-margin-left: 0.8rem;
    --icon-width: 20px;

    ${Title} {
      margin-left: var(--title-margin-left);
    }

    .nav-icon {
      width: var(--icon-width);

      transition: transform 0.2s;
    }

    &:hover {
      ${({ theme }) => theme.hover.backgroundStyle};
      ${({ theme }) => theme.hover.clickableStyle};

      .nav-icon {
        transform: scale(1.15) rotate(-7deg);
      }
    }

    ${({ isSubItem }) => {
      if (isSubItem) {
        return css`
          ${Title} {
            margin-left: calc(var(--title-margin-left) + var(--icon-width));
          }
        `;
      }
    }}

    ${({ styleParentActive, styleChildActive, theme }) => {
      const base = css`
        color: ${theme.palette.primary.main};
        svg {
          color: ${theme.palette.primary.main};
        }
      `;

      if (styleParentActive) {
        return base;
      }
      if (styleChildActive) {
        return css`
          ${base}
          background-color: #1bb69d1f;
        `;
      }
    }}

  ${SubItemsWrap} {
      margin: 0.2rem 0 0.4rem 0;
    }
  `
);
