import styled from "styled-components";
import { useCookies } from "react-cookie";

import { gql, useMutation } from "@apollo/client";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";

import { ClickableIcon } from "common/clickable-icon";
import { SlimNavigationBar } from "common/navigation-bar/slim-navigation-bar";

import { NonUserParticipantLogoutDocument } from "gql-codegen/graphql";

gql`
  mutation NonUserParticipantLogout {
    nonUserParticipantLogout {
      ok
    }
  }
`;
export const NonUserParticipantNavigationBar = styled(
  ({ className }: { className?: string }) => {
    const [logout] = useMutation(NonUserParticipantLogoutDocument);
    const [cookies] = useCookies();
    return (
      <SlimNavigationBar
        className={className}
        icon={{
          right: (
            <ClickableIcon
              icon={<ArrowLeftStartOnRectangleIcon width={24} />}
              tooltipLabel="로그아웃"
              onClick={() => {
                logout().finally(() => {
                  window.location.href =
                    "/interview-invitation?token=" + cookies["token"];
                });
              }}
            />
          ),
        }}
      />
    );
  }
)``;
