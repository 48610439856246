import styled from "styled-components";

import { gql } from "@apollo/client";

import DateTimePicker from "common/date-time-picker";
import Form from "common/form";
import { CardSection } from "common/section";
import SubmitButton from "common/submit-button";
import { FlexCol } from "common/flex";
import { queryFetcher } from "common/graphql/use-query";
import { useParams } from "common/react-router-dom/use-params";

import useToast from "common/toast/use-toast";
import {
  EditInterviewReservableTimeRangeDocument,
  InterviewType,
} from "gql-codegen/graphql";

gql`
  mutation EditInterviewReservableTimeRange(
    $groupName: String!
    $interviewId: ID!
    $reservableTimeFrom: Date!
    $reservableTimeTo: Date!
  ) {
    editInterviewReservableTimeRange(
      groupName: $groupName
      interviewId: $interviewId
      reservableTimeFrom: $reservableTimeFrom
      reservableTimeTo: $reservableTimeTo
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const InterviewReservableTimeRangeSettingSection = styled(
  ({
    className,
    interview,
  }: {
    className?: string;
    interview: Pick<InterviewType, "reservableTimeFrom" | "reservableTimeTo">;
  }) => {
    const toast = useToast();
    const { groupName, interviewId } = useParams<{
      groupName: string;
      interviewId: string;
    }>();

    return (
      <Form
        className={className}
        mutation={EditInterviewReservableTimeRangeDocument}
        initialValues={{
          groupName,
          interviewId,
          ...interview,
        }}
        onComplete={() => {
          queryFetcher.emit("InterviewDashboardPage");
          toast("인터뷰 예약 가능 시간이 정상적으로 변경되었어요.");
        }}
      >
        {({ values }) => (
          <CardSection>
            <CardSection.Title>인터뷰 예약 가능 시간 설정</CardSection.Title>
            <CardSection.Body>
              <FlexCol gap="0.8rem">
                <DateTimePicker
                  name="reservableTimeFrom"
                  label="오픈 시간"
                  helperText="오픈 시간 이전에는 면접자가 예약을 신청할 수 없어요."
                  validate={(reservableTimeFrom) => {
                    if (!reservableTimeFrom) {
                      return "오픈 시간을 입력해주세요.";
                    }

                    if (reservableTimeFrom.isAfter(values.reservableTimeTo)) {
                      return "오픈 시간은 마감 시간 이전이어야 해요.";
                    }
                  }}
                />
                <DateTimePicker
                  name="reservableTimeTo"
                  label="마감 시간"
                  helperText="마감 시간 이후에는 면접자가 예약을 수정할 수 없어요."
                  validate={(reservableTimeTo) => {
                    if (!reservableTimeTo) {
                      return "마감 시간을 입력해주세요.";
                    }
                    if (reservableTimeTo.isBefore(values.reservableTimeFrom)) {
                      return "마감 시간은 오픈 시간 이후여야 해요.";
                    }
                  }}
                />
              </FlexCol>
            </CardSection.Body>
            <CardSection.Footer>
              <SubmitButton children="저장" />
            </CardSection.Footer>
          </CardSection>
        )}
      </Form>
    );
  }
)``;
