import { useEffect, useRef, useState } from "react";
import { Alert as MuiAlert, AlertColor } from "@mui/material";
import styled from "styled-components";

import { ToastContext } from "./toast-context";

const Alert = styled(MuiAlert)`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.toast};
  color: white !important;
  font-weight: 500 !important;
  svg {
    color: white !important;
  }
  &.MuiAlert-standardSuccess {
    background: rgb(19, 148, 135) !important;
    color: white !important;
  }
  &.MuiAlert-standardError {
    background: #d22d46 !important;
    color: white !important;
  }
`;

const CLOSE_TIMEOUT = 2500;
export default function ToastProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const ref = useRef(null);
  const [showToast, setShowToast] = useState<{
    text: string;
    variant: AlertColor;
  }>({ text: "", variant: "success" });
  const [realShow, setRealShow] = useState("");

  useEffect(() => {
    if (showToast.text) {
      setRealShow(showToast.text);
      setTimeout(() => {
        setShowToast(({ text, variant }) => ({ text: "", variant }));
      }, CLOSE_TIMEOUT);
    } else {
      if (!ref.current) return;
      ref.current.style.animationDirection = "reverse";
      ref.current.classList.remove("slide-down");
      setTimeout(() => {
        ref.current.classList.add("slide-down");
      }, 100);
      setTimeout(() => {
        setRealShow("");
      }, 1000);
    }
  }, [showToast]);
  return (
    <ToastContext.Provider
      value={{
        show(text: string, variant: AlertColor = "success") {
          setShowToast({ text, variant });
        },
      }}
    >
      {!!realShow && (
        <Alert
          ref={ref}
          severity={showToast.variant}
          className="slide-down"
          children={realShow}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
}
