import { useState } from "react";
import styled from "styled-components";

import { gql } from "@apollo/client";

import { Card, CardContent } from "common/card";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "common/modal/modal";
import { Table, TableBody, TableCell, TableRow } from "common/table";
import TextField from "common/text-field";
import Form from "common/form";
import SubmitButton from "common/submit-button";
import useToast from "common/toast/use-toast";

import { CreateGroupMutationDocument } from "gql-codegen/graphql";
import { EmojiBox } from "app/login/group-card";

gql`
  mutation CreateGroupMutation($name: String!, $fullName: String!) {
    createGroup(name: $name, fullName: $fullName) {
      ok
      errors {
        name
      }
    }
  }
`;

type AddGroupCardProps = {
  className?: string;
  refetchGroupList: () => Promise<any>;
};
export const AddGroupCard = styled(
  ({ className, refetchGroupList }: AddGroupCardProps) => {
    const [show, setShow] = useState(false);
    const toast = useToast();

    const close = () => setShow(false);

    return (
      <>
        <Card className={className} onClick={() => setShow(true)}>
          <CardContent alignSelf="flex-end">
            <EmojiBox>➕</EmojiBox>
            <span>새로운 그룹 추가</span>
          </CardContent>
        </Card>
        <Form
          mutation={CreateGroupMutationDocument}
          initialValues={{
            name: "",
            fullName: "",
          }}
          onComplete={() => {
            refetchGroupList();
            toast("새로운 그룹 추가가 완료되었어요.");
            close();
          }}
        >
          {() => (
            <Modal show={show} onClose={close} variant="centered">
              <ModalHeader>새로운 그룹 추가하기</ModalHeader>
              <ModalBody>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">닉네임</TableCell>
                      <TableCell>
                        <TextField
                          name="name"
                          placeholder="binding"
                          validate={(name) => {
                            if (!name) {
                              return "닉네임을 입력하세요.";
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">그룹 이름</TableCell>
                      <TableCell>
                        <TextField
                          name="fullName"
                          placeholder="바인딩"
                          validate={(name) => {
                            if (!name) {
                              return "그룹 이름을 입력하세요.";
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <SubmitButton children="추가" />
              </ModalFooter>
            </Modal>
          )}
        </Form>
      </>
    );
  }
)`
  ${({ theme }) => theme.flex.cc};

  ${({ theme }) => theme.breakpoints.up("mobile")} {
    width: 12rem;
    height: 12rem;
  }

  ${({ theme }) => theme.breakpoints.down("mobile")} {
    width: 100%;
    height: 8rem;
  }

  ${CardContent} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    font-weight: 600;
  }

  ${({ theme }) => theme.boxShadow.defaultStyle};
  ${({ theme }) => theme.borderRadius.defaultStyle};
`;
