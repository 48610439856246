import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { InterviewListQueryDocument } from "gql-codegen/graphql";
import { Dayjs } from "dayjs";

import { gql } from "@apollo/client";

import Page from "common/page";
import { PageBody } from "common/page-body";
import { Button } from "common/button";
import { QueryRenderer } from "common/graphql/query-renderer";
import { DataTable, DataTableColumn } from "common/data-table";

import { useCreateInterviewModalForm } from "./hooks/use-create-interview-modal-form";
import { useParams } from "common/react-router-dom/use-params";

gql`
  query InterviewListQuery($groupName: String!) {
    interviewList(groupName: $groupName) {
      id
      title
      type
      reservableTimeFrom
      reservableTimeTo
    }
  }
`;

type TData = {
  id: string;
  type: string;
  title: string;
  reservableTimeFrom: Dayjs;
  reservableTimeTo: Dayjs;
};
const columns: DataTableColumn<TData>[] = [
  {
    id: "type",
    name: "유형",
    selector: (row) => row.type,
    sortable: true,
  },
  {
    id: "title",
    name: "이름",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    id: "reservableTimeFrom",
    name: "오픈시간",
    selector: ({ reservableTimeFrom }) =>
      reservableTimeFrom.format("YYYY-MM-DD a hh:mm"),
    sortable: true,
    sortFunction: (a, b) => a.reservableTimeFrom.diff(b.reservableTimeFrom),
  },
  {
    id: "reservableTimeTo",
    name: "마감시간",
    selector: ({ reservableTimeTo }) =>
      reservableTimeTo.format("YYYY-MM-DD a hh:mm"),
    sortable: true,
    sortFunction: (a, b) => a.reservableTimeTo.diff(b.reservableTimeTo),
  },
];

export const InterviewListPage = styled(
  ({ className }: { className?: string }) => {
    const { groupName } = useParams<{ groupName: string }>();
    const { renderModal, show } = useCreateInterviewModalForm();
    const navigate = useNavigate();
    return (
      <Page className={className}>
        <QueryRenderer
          refetchKey="InterviewListPage"
          variables={{
            groupName,
          }}
          query={InterviewListQueryDocument}
        >
          {({ data: { interviewList } }) => (
            <PageBody>
              {renderModal()}
              <PageBody.Title
                actions={[
                  <Button
                    variant="contained"
                    style={{ marginLeft: "auto" }}
                    onClick={show}
                  >
                    인터뷰 생성
                  </Button>,
                ]}
              >
                인터뷰 목록
              </PageBody.Title>
              <DataTable<TData>
                noDataBody="등록된 인터뷰가 없어요."
                noHeader
                columns={columns}
                data={interviewList}
                onRowClicked={(row, e) => {
                  navigate(`/group/@${groupName}/interview/${row.id}`);
                }}
                highlightOnHover
                pointerOnHover
              />
            </PageBody>
          )}
        </QueryRenderer>
      </Page>
    );
  }
)``;
