import _ from "lodash";
import { FieldHookConfig, useField } from "formik";
import { AsYouType } from "libphonenumber-js";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { TextFieldBase, TextFieldProps } from "./text-field";

export const validatePhoneNumber = (phoneNumber: string) => {
  const regEx = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return regEx.test(phoneNumber);
};

const PhoneNumField = styled(
  (
    props: Omit<FieldHookConfig<string>, "validate" | "as"> &
      JSX.IntrinsicElements["input"] &
      TextFieldProps
  ) => {
    const [error, setError] = useState("");
    const [field, meta, helpers] = useField({
      ...props,
      as: "input",
      validate: (value) => {
        const additionalValidation = props.validate?.(value);
        if (additionalValidation) {
          return additionalValidation;
        }

        if (!validatePhoneNumber(value)) {
          return "올바른 휴대폰 번호를 입력해주세요.";
        }
      },
    });

    const handleChange = (e) => {
      const newValue = new AsYouType("KR").input(e.target.value);
      helpers.setTouched(true);
      helpers.setValue(newValue);
    };

    useEffect(() => {
      meta.touched && setError(meta.error || "");
    }, [meta]);

    return (
      <TextFieldBase
        {..._.omit(props, ["validate"])}
        {...field}
        error={!!error}
        helperText={error || ""}
        type="tel"
        inputProps={{ maxLength: 13 }}
        onChange={handleChange}
      />
    );
  }
)``;

export default PhoneNumField;
