import { createContext } from "react";

export type Routes = {
  [namespace: string]: {
    [pageName: string]: any;
  };
};

export type RouterContextType = {
  routes: Routes;
};

export const RouterContext = createContext<RouterContextType>(null as any);

export const RouterContextProvider = ({
  routes,
  ...otherProps
}: {
  routes: Routes;
}) => <RouterContext.Provider value={{ routes }} {...otherProps} />;
