import _ from "lodash";
import React from "react";
import styled, { css } from "styled-components";

import { type THTMLSpanElementAttributes } from "./typings/html-element.types";

type TBadgeVariant = "primary" | "secondary" | "error" | "info";
type TBadgeSize = "small" | "medium";

export const Badge = styled(
  ({
    className,
    ...props
  }: {
    className?: string;
    variant?: TBadgeVariant;
    size?: TBadgeSize;
    children: React.ReactNode;
  } & THTMLSpanElementAttributes) => {
    return (
      <span className={className} {..._.omit(props, ["variant", "size"])} />
    );
  }
)`
  border-radius: 1rem;

  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          padding: 0.2rem 0.4rem;
          font-size: 0.8rem;
        `;
      default:
        return css`
          padding: 0.4rem 0.7rem;
          font-size: 0.9rem;
        `;
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case "primary":
        return css`
          background: ${theme.palette.primary.main};
          color: ${theme.palette.primary.contrastText};
        `;
      case "secondary":
        return `
        `;
      case "error":
        return css`
          background: ${theme.palette.error.main};
          color: ${theme.palette.primary.contrastText};
        `;

      case "info":
        return css`
          background: ${theme.gray["500"]};
          color: white;
        `;
      default:
        return `
          background: green;
        `;
    }
  }}
`;

Badge.defaultProps = {
  variant: "primary",
  size: "medium",
};
