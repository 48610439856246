import { LinearProgress } from "@mui/material";
import { useState } from "react";
import { ProgressContext } from "./progress-context";

export default function ProgressProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showProgress, setShowProgress] = useState(false);
  return (
    <ProgressContext.Provider
      value={{
        show(s) {
          setShowProgress(s);
        },
      }}
    >
      <LinearProgress
        style={{ visibility: showProgress ? "visible" : "hidden" }}
      />
      {children}
    </ProgressContext.Provider>
  );
}
