import { EventEmitter } from "events";
import { useEffect } from "react";

import {
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useApolloQuery,
} from "@apollo/client";

export const queryFetcher = new EventEmitter();

export const useQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> & {
    /**
     * refetchKey
     */
    refetchKey?: string;

    redirectMainOnUnauthorized?: boolean;
  }
): QueryResult<TData, TVariables> => {
  const queryReturns = useApolloQuery(query, options);

  const { refetchKey, redirectMainOnUnauthorized = true } = options ?? {};
  const { refetch } = queryReturns;

  useEffect(() => {
    if (refetchKey) {
      queryFetcher.on(refetchKey, refetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirectMainOnUnauthorized) {
      if (
        queryReturns.error?.graphQLErrors &&
        queryReturns.error.graphQLErrors[0] &&
        queryReturns.error.graphQLErrors[0].message === "Unauthorized"
      ) {
        alert("세션이 만료되어 로그인 페이지로 돌아갑니다.");
        window.location.href = "/";
      }
    }
  }, [queryReturns.error]);

  return queryReturns;
};
