import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ show: boolean }>`
  ${({ show }) =>
    show
      ? css`
          max-height: 300px;
          opacity: 1;
        `
      : css`
          max-height: 0;
          opacity: 0;
        `}

  transition: max-height 400ms ease-out, opacity 200ms ease-out;
`;
export const AnimatedOpener = styled(
  ({
    className,
    children,
    open,
    onClose,
  }: {
    className?: string;
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
  }) => {
    const [realShow, setRealShow] = useState(false);
    useEffect(() => {
      if (open) {
        setRealShow(true);
      } else {
        onClose();
        setRealShow(false);
      }
    }, [open, setRealShow]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <Wrapper className={className} children={children} show={realShow} />
    );
  }
)`
  overflow: hidden;
`;
