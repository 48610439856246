import EventEmitter from "events";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { ClickableIcon } from "common/clickable-icon";
import { THTMLDivElementAttributes } from "common/typings/html-element.types";
import { TableCell, TableRow } from "common/table";

const UModalHeader = ({
  children,
  ...htmlProps
}: THTMLDivElementAttributes) => {
  const close = () => {
    ModalEventEmitter.emit("close");
  };
  return (
    <div {...htmlProps}>
      <div>{children}</div>
      <ClickableIcon icon={<XMarkIcon width="1.4rem" />} onClick={close} />
    </div>
  );
};
export const ModalHeader = styled(UModalHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.modal.padding.top} ${theme.modal.padding.right}`};

  font-size: 1.2rem;
  font-weight: 600;

  border-bottom: 1px solid ${({ theme }) => theme.gray[200]};
`;

export const ModalBody = styled.div`
  padding: 1.6rem 2rem;

  ${TableRow} {
    ${TableCell} {
      padding: 0.8rem 1.2rem;
      border-bottom: unset;
    }
  }
`;

export const ModalFooter = styled.div`
  padding: ${({ theme }) =>
    `${theme.modal.padding.bottom} ${theme.modal.padding.right}`};

  border-top: 1px solid ${({ theme }) => theme.gray[200]};
`;

const ModalBackground = styled.div<{ show: boolean; showModal: boolean }>`
  ${(props) =>
    (props.show &&
      css`
        background-color: rgba(0, 0, 0, 0.35) !important;
      `) ||
    (!props.showModal &&
      css`
        z-index: -1 !important;
      `)}
`;

const ModalDialog = styled.div<{ show: boolean }>``;

export type TModalProps = {
  className?: string;
  show: boolean;
  variant?: "centered" | "right";
  onClose: () => void;
} & (
  | {
      children?: React.ReactNode;
    }
  | {
      header?: React.ReactNode;
      body?: React.ReactNode;
      footer?: React.ReactNode;
    }
);

/**
 * Used in ModalHeader, ModalBody, ModalFooter and hooks
 */
export const ModalEventEmitter = new EventEmitter();

const UModal = ({ className, show, onClose, ...props }: TModalProps) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (show) {
      setShowModal(true);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 200);
    }
  }, [show]);

  useEffect(() => {
    ModalEventEmitter.on("close", () => {
      onClose();
    });

    return () => {
      ModalEventEmitter.removeAllListeners("close");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderChildren = () => {
    if ("children" in props) {
      return props.children;
    }

    if ("header" in props) {
      const { header, body, footer } = props;
      return (
        <>
          {Boolean(header) && <ModalHeader>{header}</ModalHeader>}
          {Boolean(body) && <ModalBody>{body}</ModalBody>}
          {Boolean(footer) && <ModalFooter>{footer}</ModalFooter>}
        </>
      );
    }
  };

  return (
    <div className={className}>
      <ModalBackground
        className="modal-background"
        onClick={onClose}
        show={show}
        showModal={showModal}
      />
      {showModal && <ModalDialog show={show}>{renderChildren()}</ModalDialog>}
    </div>
  );
};

export const Modal = styled(UModal)`
  ${ModalBackground} {
    position: fixed;
    inset: 0px;
    transition: background-color 0.15s ease-in-out;
    z-index: ${({ theme }) => theme.zIndex.modal.background};
    background-color: transparent;
  }

  ${ModalDialog} {
    overflow-y: auto;

    z-index: ${({ theme }) => theme.zIndex.modal.dialog};

    display: flex;
    flex-direction: column;

    ${({ theme, variant }) => {
      if (variant === "centered") {
        return css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          ${theme.borderRadius.defaultStyle};
        `;
      }
      if (variant === "right") {
        return css`
          top: 0;
          right: 0;

          height: ${({ theme }) => theme.vh(100)};

          animation: ease-in-out;
          animation-name: right-to-left;
          animation-duration: 0.35s;
        `;
      }
    }}

    transition: opacity 0.15s ease-in-out;

    position: fixed;

    max-width: 100vw;
    width: 30rem;

    ${ModalFooter} {
      margin-top: auto;
    }

    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    opacity: 0;
    ${(props) =>
      props.show &&
      css`
        opacity: 1;
      `}
  }

  @keyframes right-to-left {
    0% {
      opacity: 0;
      right: -17rem;
      transform: scale(0.99);
    }
    100% {
      opacity: 1;
      right: 0rem;
      transform: scale(1);
    }
  }
`;

Modal.defaultProps = {
  variant: "right",
};
