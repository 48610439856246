import styled from "styled-components";

import { FlexRow } from "common/flex";
import type { THTMLDivElementAttributes } from "common/typings/html-element.types";

const SubSectionTitle = styled.div`
  margin-bottom: 0.6rem;
  font-size: 1rem;
`;

const SubSectionBody = styled.div``;

const _SubSection = styled.div`
  & + & {
    margin-top: 1.8rem;
  }
`;

export const SubSection = _SubSection as typeof _SubSection & {
  Title: typeof SubSectionTitle;
  Body: typeof SubSectionBody;
};
SubSection.Title = SubSectionTitle;
SubSection.Body = SubSectionBody;
