import styled from "styled-components";

import { gql } from "@apollo/client";

import { CardSection } from "common/section";
import { QueryRenderer } from "common/graphql/query-renderer";
import { KebabMenu } from "common/kebab-menu/kebab-menu";
import { useParams } from "common/react-router-dom/use-params";
import { Badge } from "common/badge";

import { GroupAdminListSectionQueryDocument } from "gql-codegen/graphql";
import useToast from "common/toast/use-toast";
import { useDeleteGroupAdminInvitationMutation } from "./hooks/use-delete-group-admin-invitation-mutation";
import { useReSendGroupAdminInvitationMutation } from "./hooks/use-re-send-group-admin-invitation-mutation";
import { useDeleteGroupAdminModalForm } from "./hooks/use-delete-group-admin-modal";
import { DataTable } from "common/data-table";

gql`
  query GroupAdminListSectionQuery($groupName: String!) {
    groupAdmins(groupName: $groupName) {
      id
      username
      emailAddress
      phoneNumber
    }
    groupAdminInvitations(groupName: $groupName) {
      inviteeName
      inviteeEmail
      invited
    }
  }
`;

const KB = ({
  showGroupAdminDeleteButton,
  user,
}: {
  showGroupAdminDeleteButton: boolean;
  user: { id: string; username: string };
}) => {
  const {
    renderModal: renderDeleteGroupAdminModal,
    show: showDeleteGroupAdminModal,
  } = useDeleteGroupAdminModalForm({ user });

  return (
    <>
      {renderDeleteGroupAdminModal()}
      <KebabMenu
        items={[
          showGroupAdminDeleteButton && {
            label: "관리자 삭제",
            onClick: () => showDeleteGroupAdminModal(),
          },
        ]}
      />
    </>
  );
};

export const GroupAdminListSection = styled(
  ({ className }: { className?: string }) => {
    const { groupName } = useParams<{ groupName: string }>();
    const toast = useToast();

    const [deleteGroupAdminInvitation] =
      useDeleteGroupAdminInvitationMutation();
    const [reSendGroupAdminInvitation] =
      useReSendGroupAdminInvitationMutation();

    return (
      <QueryRenderer
        refetchKey="groupAdminListSection"
        query={GroupAdminListSectionQueryDocument}
        variables={{ groupName }}
      >
        {({ data: { groupAdmins, groupAdminInvitations }, refetch }) => {
          return (
            <>
              <CardSection className={className}>
                <CardSection.Title>관리자 목록</CardSection.Title>
                <CardSection.Body>
                  <DataTable
                    columns={[
                      { id: "name", name: "이름", selector: (r) => r.username },
                      {
                        id: "emailAddress",
                        name: "이메일 주소",
                        selector: (r) => r.emailAddress,
                      },
                      {
                        id: "phoneNumber",
                        name: "휴대폰 번호",
                        selector: (r) => r.phoneNumber,
                      },
                      {
                        id: "moreButton",
                        cell: ({ username, id }) => (
                          <KB
                            showGroupAdminDeleteButton={groupAdmins.length > 1}
                            user={{ username, id }}
                          />
                        ),
                      },
                    ]}
                    data={groupAdmins}
                  />
                </CardSection.Body>
              </CardSection>
              <CardSection className={className}>
                <CardSection.Title>관리자 초대 목록</CardSection.Title>
                <CardSection.Body>
                  <DataTable
                    noDataBody="초대 중인 관리자가 없어요"
                    columns={[
                      {
                        id: "name",
                        name: "이름",
                        selector: (r) => r.inviteeName,
                      },
                      {
                        id: "emailAddress",
                        name: "이메일 주소",
                        selector: (r) => r.inviteeEmail,
                      },
                      {
                        id: "phoneNumber",
                        name: "휴대폰 번호",
                        selector: (r) => "",
                      },
                      {
                        id: "status",
                        name: "초대 상태",
                        cell: (r) =>
                          r.invited ? (
                            <Badge variant="primary" size="small">
                              초대완료
                            </Badge>
                          ) : (
                            <Badge variant="info" size="small">
                              초대중
                            </Badge>
                          ),
                      },
                      {
                        id: "moreButton",
                        cell: ({ invited, inviteeEmail }) => (
                          <KebabMenu
                            items={
                              invited
                                ? []
                                : [
                                    {
                                      label: "초대 삭제",
                                      onClick: () => {
                                        deleteGroupAdminInvitation({
                                          variables: {
                                            groupName,
                                            inviteeEmail,
                                          },
                                        }).then(() => {
                                          refetch();
                                          toast("관리자 초대를 삭제했어요.");
                                        });
                                      },
                                    },
                                    {
                                      label: "초대 메일 재발송",
                                      onClick: () => {
                                        reSendGroupAdminInvitation({
                                          variables: {
                                            groupName,
                                            inviteeEmail,
                                          },
                                        }).then(() => {
                                          refetch();
                                          toast(
                                            "관리자 초대 메일을 재발송했어요."
                                          );
                                        });
                                      },
                                    },
                                  ]
                            }
                          />
                        ),
                      },
                    ]}
                    data={groupAdminInvitations}
                  />
                </CardSection.Body>
              </CardSection>
            </>
          );
        }}
      </QueryRenderer>
    );
  }
)`
  ${CardSection.Title} {
    display: flex;
    justify-content: space-between;
  }
`;
