import { useField } from "formik";
import { useEffect, useState } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { theme } from "theme/mui-theme";

export const SelectField = <T extends string | number>({
  variant = "outlined",
  items,
  validate,
  ...props
}: Omit<SelectProps, "variant"> & {
  name: string;
  validate?: (value: T) => string;
  variant?: SelectProps["variant"];
  items: {
    label: React.ReactNode;
    value: T;
  }[];
}) => {
  const [error, setError] = useState("");

  const formikArgs = { name: props.name, validate };
  const [field, meta] = useField<T>(formikArgs);

  useEffect(() => {
    meta.touched && setError(meta.error || "");
  }, [meta]);

  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        MenuProps={{
          disablePortal: true,
          hideBackdrop: true,
          // @ts-expect-error: DatePicker가 Select를 가리는 현상이 있음
          style: { zIndex: theme.zIndex.modal.dialog },
        }}
        error={Boolean(error)}
        variant={variant}
        {...props}
        {...field}
      >
        {items.map((item) => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </FormControl>
  );
};
