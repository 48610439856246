import { useMemo, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Drawer, useMediaQuery } from "@mui/material";
import {
  UsersIcon,
  NewspaperIcon,
  ArrowLeftStartOnRectangleIcon,
  WrenchScrewdriverIcon,
  Bars3Icon,
  HomeIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";
import { gql, useMutation } from "@apollo/client";

import { FlexCol, FlexRow } from "common/flex";
import { useParams } from "common/react-router-dom/use-params";
import { ClickableIcon } from "common/clickable-icon";
import { MenuItem } from "common/menu/menu-item";
import { Menu } from "common/menu/menu";

import { useGroupContext } from "packages/group/group-context";
import { LogoutDocument } from "gql-codegen/graphql";
import { NavigationBarItem } from "./navigation-bar-item";
import { SlimNavigationBar } from "./slim-navigation-bar";

gql`
  mutation Logout {
    logout {
      ok
    }
  }
`;

const EmojiBox = styled.div`
  ${({ theme }) => theme.flex.cc};
  flex-shrink: 0;

  width: 2.8rem;
  height: 2.8rem;

  background-color: #dbeef7;

  font-size: 1.3rem;
  ${({ theme }) => theme.borderRadius.defaultStyle};

  transition: all 0.3s ease 0s;
`;

const ItemWrapper = styled.div``;
const GroupInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  padding: 0.4rem 0.8rem;

  background: rgb(244, 248, 250);

  ${({ theme }) => theme.borderRadius.defaultStyle};

  &:hover {
    ${({ theme }) => theme.hover.backgroundStyle};
    ${({ theme }) => theme.hover.clickableStyle};
  }
`;
const GroupName = styled.span`
  min-width: 7rem;

  font-size: 0.9rem;
  font-weight: 600;
  word-break: keep-all;

  line-height: 1.2;
`;

const Logo = styled.span`
  font-size: 1.5rem;
`;
const Title = styled.span`
  font-family: "Inter";
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.9px;

  color: #e6a100;
`;

const SMenuItem = styled(MenuItem)`
  gap: 0.8rem;
  padding: 0.6rem 1.2rem;
  background: white;
  font-size: 14px;
  font-weight: 500;
  svg {
    width: 20px;
    color: ${({ theme }) => theme.gray[500]};
  }
`;
const _UNavigationBar = ({ className }: { className?: string }) => {
  const { groupName } = useParams<{ groupName: string }>();
  const { group } = useGroupContext();

  const [logout] = useMutation(LogoutDocument);

  const items = useMemo(
    () =>
      groupName
        ? [
            {
              title: "학생 관리",
              to: "",
              Icon: UsersIcon,
            },
            {
              title: "인터뷰",
              Icon: NewspaperIcon,
              subItems: [
                {
                  title: "인터뷰 목록",
                  to: `/group/@${groupName}/interview`,
                },
              ],
            },
            {
              title: "설정",
              Icon: WrenchScrewdriverIcon,
              subItems: [
                {
                  title: "그룹 기본 설정",
                  to: `/group/@${groupName}/setting/general`,
                },
                {
                  title: "관리자 목록",
                  to: `/group/@${groupName}/setting/admin`,
                },
              ],
            },
            {
              title: "바인딩 가이드",
              Icon: BookOpenIcon,
              onClick: () => window.open("https://blog.binding.at", "_blank"),
            },
          ]
        : [
            {
              title: "바인딩 가이드",
              Icon: BookOpenIcon,
              onClick: () => window.open("https://blog.binding.at", "_blank"),
            },
          ],
    [groupName]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const open = Boolean(anchorEl);

  return (
    <div className={className}>
      {group ? (
        <>
          <GroupInfoWrapper onClick={(e) => setAnchorEl(e.currentTarget)}>
            <EmojiBox>🎈</EmojiBox>
            <GroupName>{group?.fullName}</GroupName>
          </GroupInfoWrapper>
          <Menu
            slotProps={{
              root: {
                disablePortal: true,
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Link to="/">
              <SMenuItem>
                <HomeIcon />
                <span>홈으로 돌아가기</span>
              </SMenuItem>
            </Link>
          </Menu>
        </>
      ) : (
        <FlexRow alignItems="center" gap="0.2rem">
          <Logo>👋</Logo>
          <Title>Binding</Title>
        </FlexRow>
      )}
      <ItemWrapper>
        {items.map((props) => (
          <NavigationBarItem key={props.title} {...props} />
        ))}
        <FlexCol flex={1} />
        <NavigationBarItem
          title="로그아웃"
          onClick={() =>
            logout().finally(() => {
              window.location.assign("/");
            })
          }
          Icon={ArrowLeftStartOnRectangleIcon}
        />
      </ItemWrapper>
    </div>
  );
};

// prettier-ignore
const _NavigationBar = styled(_UNavigationBar)(({ theme }) => `
  ${theme.breakpoints.down("mobile")} {
    position: static;
  }

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.navigationBar};

  .MuiPopover-root {
    z-index: ${theme.zIndex.navigationBar};
  }

  width: ${theme.navigationBar.primary.width};
  height: ${theme.vh(100)};
  padding: ${theme.page.padding.primary.top} 0.8rem;

  background-color: ${theme.page.backgroundColor};

  border-bottom: 1px solid transparent;
  transition: width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    background-color 0.2s ease;

  ${Logo} {
    margin-left: 0.8rem;
  }

  ${ItemWrapper} {
    ${theme.flex.col}
    ${theme.flex.ible}

    margin-top: 1.6rem;
  }
`
);

export const NavigationBar = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1224px)");

  if (isMobile) {
    return (
      <>
        <SlimNavigationBar
          icon={{
            left: (
              <ClickableIcon
                icon={<Bars3Icon width={24} />}
                onClick={() => setOpen(true)}
              />
            ),
          }}
        />
        <Drawer open={open} onClose={() => setOpen(false)}>
          <_NavigationBar />
        </Drawer>
      </>
    );
  }

  return <_NavigationBar />;
};
