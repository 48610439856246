import { ReactNode, useCallback, useState } from "react";
import { createPortal } from "react-dom";

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  TModalProps,
} from "../modal";
import Form, { TFormChildrenArgs, TFormProps } from "../../form";

type TNode<TResult, TVariables> =
  | ReactNode
  | ((formArgs: TFormChildrenArgs<TResult, TVariables>) => ReactNode);

type TUseModalFormProps<TResult, TVariables> = {
  header?: TNode<TResult, TVariables>;
  body?: TNode<TResult, TVariables>;
  footer?: TNode<TResult, TVariables>;
  formProps: Omit<TFormProps<TResult, TVariables>, "children">;
} & Omit<TModalProps, "children" | "show" | "onClose">;

export const InternalModal = ({
  show,
  setShow,
  header,
  body,
  footer,
  modalProps,
  formArgs,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  header: TNode<any, any>;
  body: TNode<any, any>;
  footer: TNode<any, any>;
  modalProps: Omit<TModalProps, "children" | "show" | "onClose">;
  formArgs;
}) => {
  return (
    <Modal show={show} onClose={() => setShow(false)} {...modalProps}>
      <>
        {Boolean(header) && (
          <ModalHeader>
            {typeof header === "function" ? header(formArgs) : header}
          </ModalHeader>
        )}
        {Boolean(body) && (
          <ModalBody>
            {typeof body === "function" ? body(formArgs) : body}
          </ModalBody>
        )}
        {Boolean(footer) && (
          <ModalFooter>
            {typeof footer === "function" ? footer(formArgs) : footer}
          </ModalFooter>
        )}
      </>
    </Modal>
  );
};

export const useModalForm = <TResult, TVariables>({
  header,
  body,
  footer,
  formProps,
  ...modalProps
}: TUseModalFormProps<TResult, TVariables>) => {
  const [show, setShow] = useState(false);

  const renderModal = useCallback(() => {
    return createPortal(
      <Form {...formProps}>
        {(formArgs) => (
          <InternalModal
            show={show}
            setShow={setShow}
            header={header}
            body={body}
            footer={footer}
            modalProps={modalProps}
            formArgs={formArgs}
          />
        )}
      </Form>,
      document.body
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header, show]);

  return {
    renderModal,
    show: () => setShow(true),
    hide: () => setShow(false),
  };
};
