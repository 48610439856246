import styled, { css } from "styled-components";
import { useState } from "react";

import { gql } from "@apollo/client";

import { PageBody } from "common/page-body";
import Form from "common/form";
import TextField from "common/text-field";
import SubmitButton from "common/submit-button";
import { FlexCol, FlexRow } from "common/flex";
import { RadioField } from "common/radio-field";
import PhoneNumField from "common/phone-num-field";
import { EmailField } from "common/email-field";
import { useQueryString } from "common/query-string/hooks/use-query-string";

import {
  ENonUserParticipantVerificiationType,
  RequestNonUserParticipantVerificationNumberDocument,
} from "gql-codegen/graphql";
import { LoginPageLayout } from "app/login/login-page-layout";

import { useInterviewInvitationLoginPagePath } from "../interview-invitation-login-page-path-context";
import { LoginForm } from "app/login/login-form";

gql`
  mutation RequestNonUserParticipantVerificationNumber(
    $token: String!
    $verificationType: ENonUserParticipantVerificiationType!
    $emailAddress: String
    $phoneNumber: String
  ) {
    requestNonUserParticipantVerificationNumber(
      token: $token
      verificationType: $verificationType
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
    ) {
      ok
      phoneNumber
      emailAddress
      errors {
        _
      }
    }
  }
`;

const TitleWrapper = styled.div``;
const LoginFieldBox = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;

  width: fit-content;
  padding: 2px;
  border-radius: 20px;

  background: ${({ theme }) => theme.gray[300]};
`;
const SwitchButton = styled.button<{ selected?: boolean }>`
  background: none;
  border: none;
  border-radius: 20px;
  padding: 0.4rem 1.2rem;

  font-size: 1rem;

  cursor: pointer;

  ${({ selected }) => {
    return (
      selected &&
      css`
        background: white;
      `
    );
  }}
`;

const Logo = styled.span`
  font-size: 1.6rem;
`;
const Title = styled.span`
  font-family: "Inter";
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -0.9px;

  color: #e6a100;
`;
const CatchPhrase = styled.span`
  line-height: 1.2;
  font-weight: 800;
  font-size: 2.8rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const UInterviewInvitationLoginPage = ({
  className,
}: {
  className?: string;
}) => {
  const { search } = useQueryString<{ token: string }>();
  const [selected, setSelected] = useState("비회원");
  const { setLoginPageContext } = useInterviewInvitationLoginPagePath();

  return (
    <LoginPageLayout className={className}>
      <PageBody>
        <FlexRow>
          <FlexCol justifyContent="center" gap="0.4rem">
            <FlexRow alignItems="center" gap="0.2rem">
              <Logo>👋</Logo>
              <Title>Binding</Title>
            </FlexRow>
            <CatchPhrase>
              쉽고 편리한
              <br />
              인터뷰 예약 솔루션
            </CatchPhrase>
          </FlexCol>
        </FlexRow>
        {/* TODO: 회원이신가요? 비회원이신가요? 로 변겨 */}
        {/* <SwitchWrapper>
          {["회원", "비회원"].map((v) => (
            <SwitchButton
              key={v}
              selected={selected === v}
              onClick={() => setSelected(v)}
              children={v}
            />
          ))}
        </SwitchWrapper> */}
        {selected === "회원" ? (
          <LoginForm
            onComplete={() => {
              // TODO: fetch interview info
              // and navigate to interview page
              window.location.assign("/");
            }}
          />
        ) : (
          <Form
            key="nonUserParticipantLoginForm"
            initialValues={{
              token: search["token"],
              verificationType: ENonUserParticipantVerificiationType.Phone,
              emailAddress: "",
              phoneNumber: "",
            }}
            mutation={RequestNonUserParticipantVerificationNumberDocument}
            validate={({ token }) => {
              if (!token) {
                return { _: "정상적인 접근이 아닙니다." };
              }
            }}
            onComplete={({
              requestNonUserParticipantVerificationNumber: {
                phoneNumber,
                emailAddress,
              },
            }) => {
              if (phoneNumber) {
                setLoginPageContext({
                  verificationType: "phone",
                  phoneNumber,
                });
              }
              if (emailAddress) {
                setLoginPageContext({
                  verificationType: "email",
                  emailAddress,
                });
              }
            }}
          >
            {({ values }) => (
              <LoginFieldBox>
                <RadioField
                  name="verificationType"
                  items={[
                    {
                      label: "휴대폰 번호",
                      value: ENonUserParticipantVerificiationType.Phone,
                    },
                    {
                      label: "이메일 주소",
                      value: ENonUserParticipantVerificiationType.Email,
                    },
                  ]}
                />
                {values.verificationType ===
                ENonUserParticipantVerificiationType.Phone ? (
                  <PhoneNumField
                    name="phoneNumber"
                    label="휴대폰 번호"
                    autoComplete="tel"
                    autoFocus
                    validate={(value) =>
                      !value && "휴대폰 번호를 입력해주세요."
                    }
                  />
                ) : (
                  <EmailField
                    name="emailAddress"
                    label="이메일 주소"
                    autoComplete="email"
                    validate={(value) =>
                      !value && "이메일 주소를 입력해주세요."
                    }
                  />
                )}
                <SubmitButton children="로그인" variant="contained" />
              </LoginFieldBox>
            )}
          </Form>
        )}
      </PageBody>
    </LoginPageLayout>
  );
};

// prettier-ignore
export const InterviewInvitationLoginPage = styled(UInterviewInvitationLoginPage)`
  ${PageBody} {
    ${LoginFieldBox} {
      display: flex;
      flex-direction: column;

      ${TextField} {
        margin-top: 0.8rem;
      }
      ${RadioField} {
        margin-bottom: 0.4rem;
      }
      ${SubmitButton} {
        margin-top: 1.6rem;
      }
    }
  }
`;
