import styled from "styled-components";

import { PageBody } from "common/page-body";
import { FlexCol, FlexRow } from "common/flex";

import { LoginPageLayout } from "./login-page-layout";
import { LoginForm } from "./login-form";

const Logo = styled.span`
  font-size: 1.6rem;
`;
const Title = styled.span`
  font-family: "Inter";
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -0.9px;

  color: #e6a100;
`;
const CatchPhrase = styled.span`
  line-height: 1.2;
  font-weight: 800;
  font-size: 2.8rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;
function UnstyledLoginPage({ className }: { className?: string }) {
  return (
    <LoginPageLayout className={className}>
      <PageBody>
        <FlexCol justifyContent="center" gap="0.4rem">
          <FlexRow alignItems="center" gap="0.2rem">
            <Logo>👋</Logo>
            <Title>Binding</Title>
          </FlexRow>
          <CatchPhrase>
            쉽고 편리한
            <br />
            인터뷰 예약 솔루션
          </CatchPhrase>
        </FlexCol>
        <LoginForm />
      </PageBody>
    </LoginPageLayout>
  );
}
const LoginPage = styled(UnstyledLoginPage)``;
export default LoginPage;
