import { createSlice } from "@reduxjs/toolkit";

export interface GroupState {
  name: string;
  fullName: string;
}

const initialState: GroupState = {
  name: "",
  fullName: "",
};

type LoginActionType = {
  type: string;
  payload: GroupState;
};

export const groupSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setGroup(state, action: LoginActionType) {
      if (!action.payload) return;
      const { name, fullName } = action.payload;
      return {
        name,
        fullName,
      };
    },
  },
});

export const { setGroup } = groupSlice.actions;

export default groupSlice.reducer;
