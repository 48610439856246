import styled from "styled-components";

const FooterBody = styled.div``;

const CompanyName = styled.div``;
const Logo = styled.img``;

const UFooter = ({ className }: { className?: string }) => {
  return (
    <footer className={className}>
      <FooterBody>
        <div>
          <CompanyName>리드랩</CompanyName>
          <span>사업자등록번호: 225-04-68233</span>
          <span>대표: 이동주</span>
          <br />
          <span>통신판매업번호: 2022-서울성동-01120</span>
          <span>
            <a
              target="_blank"
              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2250468233"
            >
              사업자정보확인
            </a>
          </span>
          <br />
          <span>
            주소: 서울특별시 성동구 한림말길 50(옥수동, 옥수하이츠아파트) 105동
            901호
          </span>
          <br />
          <span>연락처: 010-3603-0182</span>
          <br />
          <span>Copyright 2024 © LEEDLAB. All Rights Reserved.</span>
          <br />
          <br />
          <b>
            <span>
              <a
                target="_blank"
                href="https://plip.kr/pcc/4eeac96e-611f-4047-976b-4bf0efb149ff/privacy/1.html"
              >
                개인정보처리방침
              </a>
            </span>
          </b>
        </div>
      </FooterBody>
    </footer>
  );
};

// prettier-ignore
export const Footer = styled(UFooter)(({ theme }) => `
  width: 100%;

  padding-bottom: ${theme.page.padding.primary.top};
  margin-bottom: 0;

  background-color: ${theme.page.backgroundColor};
  color: rgb(150, 150, 150);

  ${FooterBody} {
    display: flex;
    gap: 1.8rem;

    max-width:${theme.page.maxWidth};

    margin: 0 auto;
    padding: 0 ${theme.page.padding.primary.left};

    font-size: 0.8rem;
    line-height: 1.6;
    word-break: keep-all;

    ${CompanyName} {
      margin-bottom: 0.4rem;

      font-weight: 600;
    }

    ${Logo} {
      width: 5.6rem;
      cursor: pointer;
    }

    span + span {
      margin-left: 0.6rem;
    }

    @media (max-width: 720px) {
      flex-direction: column;
    }
  }
`
);
