import styled, { css } from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  CardContent as MuiCardContent,
} from "@mui/material";

export const CardActionArea = styled(MuiCardActionArea)``;

const Box = styled.div``;
const UCard = ({
  className,
  to,
  children,
  disabled,
  ...htmlAttrs
}: {
  className?: string;
  to?: string;
  children: React.ReactNode;
  disabled?: boolean;
  direction?: React.CSSProperties["flexDirection"];
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <MuiCard
      className={className}
      component={Box}
      {...htmlAttrs}
      onClick={(e) => {
        if (!disabled) {
          htmlAttrs.onClick?.(e);
        }
      }}
    >
      {to ? (
        <Link to={to} style={{ flex: 1 }}>
          <CardActionArea disableRipple={disabled}>{children}</CardActionArea>
        </Link>
      ) : (
        <CardActionArea disableRipple={disabled}>{children}</CardActionArea>
      )}
    </MuiCard>
  );
};

export const Card = styled(UCard)`
  display: flex;
  position: relative;
  height: 6rem;

  ${({ theme }) => theme.boxShadow.defaultStyle};
  ${({ theme }) => theme.borderRadius.defaultStyle};

  ${CardActionArea} {
    display: inline-flex;
    ${({ direction }) => {
      if (direction) {
        return css`
          flex-direction: ${direction};
        `;
      }
    }}

    height: 100%;

    ${({ theme }) => theme.borderRadius.defaultStyle};
  }
`;

export const CardContent = styled(MuiCardContent).withConfig({
  shouldForwardProp: (prop) => !["alignSelf"].includes(prop),
})<{
  alignSelf?: React.CSSProperties["alignSelf"];
}>`
  flex: 1;
  font-weight: 500;

  padding: 20px;
  padding-bottom: 16px !important;

  ${({ alignSelf }) => {
    if (alignSelf) {
      return css`
        align-self: ${alignSelf};
      `;
    }
  }}
`;
