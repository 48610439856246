import { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Formats the seconds to a string in the format of "m:ss"
 */
export const CountDown = styled(
  ({ className, initial }: { className?: string; initial: number }) => {
    const [totalSeconds, setTotalSeconds] = useState(initial);

    useEffect(() => {
      const interval = setInterval(() => {
        setTotalSeconds((prev) => {
          if (prev === 0) {
            clearInterval(interval);
            return 0;
          }

          return prev - 1;
        });
      }, 1_000);
      return () => clearInterval(interval);
    }, []);

    const toMinutes = Math.floor(totalSeconds / 60);
    const toSeconds = (totalSeconds % 60).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });

    return (
      <span className={className}>
        {toMinutes}:{toSeconds}
      </span>
    );
  }
)``;
