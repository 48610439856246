import { gql, useMutation } from "@apollo/client";

import { ReSendGroupAdminInvitationDocument } from "gql-codegen/graphql";

gql`
  mutation ReSendGroupAdminInvitation(
    $groupName: String!
    $inviteeEmail: String!
  ) {
    reSendGroupAdminInvitation(
      groupName: $groupName
      inviteeEmail: $inviteeEmail
    ) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useReSendGroupAdminInvitationMutation = () => {
  return useMutation(ReSendGroupAdminInvitationDocument);
};
