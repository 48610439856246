import { createContext, useContext } from "react";

export type TValue =
  | {
      verificationType: "email";
      emailAddress: string;
    }
  | {
      verificationType: "phone";
      phoneNumber: string;
    }
  | null;

export const InterviewInvitationLoginPagePathContext = createContext<{
  loginPageContext: TValue;
  setLoginPageContext: React.Dispatch<React.SetStateAction<TValue>>;
}>(null as any);

export const useInterviewInvitationLoginPagePath = () =>
  useContext(InterviewInvitationLoginPagePathContext);
