import Page from "common/page";
import { PageBody } from "common/page-body";
import styled from "styled-components";

export const LoginPageLayout = styled(Page).attrs({
  showNav: false,
})`
  justify-content: center;

  min-height: ${({ theme }) => theme.vh(100)};

  background: ${({ theme }) => theme.page.backgroundColor};

  ${PageBody} {
    ${({ theme }) => theme.flex.col};
    justify-content: center;
    gap: 1.6rem;

    padding-bottom: 12vh;

    max-width: 460px;
    border-radius: 20px;
  }
`;
