import styled from "styled-components";

import TextField, { TextFieldProps } from "./text-field";

export const NumberField = styled(
  ({
    className,
    name,
    placeholder,
    maxLength,
    min,
    max,
  }: Pick<
    TextFieldProps,
    "className" | "name" | "placeholder" | "maxLength" | "min" | "max"
  >) => {
    return (
      <TextField
        className={className}
        name={name}
        placeholder={placeholder}
        type="text"
        inputProps={{
          maxLength: maxLength,
          inputMode: "numeric",
        }}
      />
    );
  }
)``;
