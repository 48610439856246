import { useState } from "react";

import { InterviewInvitationPhoneVerificationPage } from "./interview-invitation-verification/interview-invitation-phone-verification-page";
import {
  InterviewInvitationLoginPagePathContext,
  type TValue,
} from "./interview-invitation-login-page-path-context";
import { InterviewInvitationLoginPage } from "./interview-invitation-login-page/interview-invitation-login-page";
import { InterviewInvitationEmailVerificationPage } from "./interview-invitation-verification/interview-invitation-email-verification-page";

export const InterviewInvitationLoginPagePath = () => {
  const [loginPageContext, setLoginPageContext] = useState<TValue>(null);
  return (
    <InterviewInvitationLoginPagePathContext.Provider
      value={{
        loginPageContext,
        setLoginPageContext,
      }}
    >
      <InterviewInvitationLoginPagePathContext.Consumer>
        {({ loginPageContext }) => {
          // return (
          //   <InterviewInvitationPhoneVerificationPage
          //     //   @ts-ignore
          //     phoneNumber={"010-3699-3699"}
          //   />
          // );

          // return (
          //   <InterviewInvitationEmailVerificationPage
          //     //   @ts-ignore
          //     emailAddress={"me@leed.at"}
          //   />
          // );

          if (loginPageContext === null) {
            return <InterviewInvitationLoginPage />;
          }

          if (loginPageContext.verificationType === "phone") {
            return (
              <InterviewInvitationPhoneVerificationPage
                //   @ts-ignore
                phoneNumber={loginPageContext.phoneNumber}
              />
            );
          }

          if (loginPageContext.verificationType === "email") {
            return (
              <InterviewInvitationEmailVerificationPage
                //   @ts-ignore
                emailAddress={loginPageContext.emailAddress}
              />
            );
          }
        }}
      </InterviewInvitationLoginPagePathContext.Consumer>
    </InterviewInvitationLoginPagePathContext.Provider>
  );
};
