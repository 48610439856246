import styled from "styled-components";

import { gql } from "@apollo/client";

import Page from "common/page";
import { PageBody } from "common/page-body";
import { CardSection } from "common/section";
import Form from "common/form";
import TextField from "common/text-field";
import SubmitButton from "common/submit-button";
import { useParams } from "common/react-router-dom/use-params";

import useToast from "common/toast/use-toast";
import { useGroupContext } from "packages/group/group-context";
import { EditGroupLinkDocument } from "gql-codegen/graphql";

gql`
  mutation EditGroupLink($groupName: String!, $pfKakaoURL: String!) {
    editGroupLink(groupName: $groupName, pfKakaoURL: $pfKakaoURL) {
      ok
    }
  }
`;

const UGroupManagePage = ({ className }: { className?: string }) => {
  const toast = useToast();
  const { groupName } = useParams<{ groupName: string }>();

  const { group, refetchContext } = useGroupContext();

  return (
    <Page className={className}>
      <PageBody>
        <PageBody.Title>그룹 기본 설정</PageBody.Title>
        <PageBody.Body>
          <Form
            initialValues={{
              groupName,
              pfKakaoURL: group?.pfKakaoURL ?? "",
            }}
            mutation={EditGroupLinkDocument}
            onComplete={() => {
              toast("링크 수정이 완료되었어요.");
              refetchContext();
            }}
          >
            {() => (
              <CardSection>
                <CardSection.Title>카카오톡 채널 설정</CardSection.Title>
                <CardSection.Body>
                  <TextField
                    name="pfKakaoURL"
                    label="💬 카카오톡 채널 링크"
                    placeholder="https://pf.kakao.com/ABCDEF"
                    helperText="인터뷰 대상자들이 문의를 요청할 곳이에요."
                    type="url"
                    variant="filled"
                    inputProps={{
                      maxLength: 1_000,
                    }}
                  />
                </CardSection.Body>
                <CardSection.Footer>
                  <SubmitButton children="저장" />
                </CardSection.Footer>
              </CardSection>
            )}
          </Form>
        </PageBody.Body>
      </PageBody>
    </Page>
  );
};

export const GroupManagePage = styled(UGroupManagePage)``;
