import LogRocket from "logrocket";

import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  id: string;
  username: string;
  phoneNumber: string;
}

const initialState: UserState = {
  id: "",
  username: "",
  phoneNumber: "",
};

type LoginActionType = {
  type: string;
  payload: UserState;
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action: LoginActionType) {
      if (!action.payload) return;
      const { id, username, phoneNumber } = action.payload;
      LogRocket.identify(String(id), {
        username,
        phoneNumber,
      });
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login } = userSlice.actions;

export default userSlice.reducer;
