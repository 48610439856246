import { gql } from "@apollo/client";

import { useModalForm } from "common/modal/hooks/use-modal-form";
import { useParams } from "common/react-router-dom/use-params";
import SubmitButton from "common/submit-button";
import { queryFetcher } from "common/graphql/use-query";
import { ModalEventEmitter } from "common/modal/modal";
import { DangerPanel } from "common/danger-panel";

import useToast from "common/toast/use-toast";
import { DeleteGroupAdminMutationDocument } from "gql-codegen/graphql";

gql`
  mutation DeleteGroupAdminMutation($groupName: String!, $userId: ID!) {
    deleteGroupAdmin(groupName: $groupName, userId: $userId) {
      ok
      errors {
        _
      }
    }
  }
`;

export const useDeleteGroupAdminModalForm = ({
  user,
}: {
  user: { id: string; username: string };
}) => {
  const { groupName } = useParams<{ groupName: string }>();
  const toast = useToast();

  return useModalForm({
    header: "관리자 삭제",
    body: (
      <DangerPanel>
        정말로 관리자를 삭제하시겠어요?
        <br />
        삭제 이후에 <b>{user.username}</b> 관리자는 더이상 그룹에 접속할 수
        없어요.
      </DangerPanel>
    ),
    footer: <SubmitButton color="error" children="삭제" />,
    formProps: {
      enableReinitialize: true,
      initialValues: {
        groupName,
        userId: user.id,
      },
      mutation: DeleteGroupAdminMutationDocument,
      onComplete: () => {
        toast("관리자 삭제가 완료되었어요.");
        queryFetcher.emit("groupAdminListSection");
        ModalEventEmitter.emit("close");
      },
    },
  });
};
